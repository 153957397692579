import { devtools } from 'zustand/middleware';
import create from 'zustand';
import { PUBLIC, SECRETS } from '../../constants/objectives';
import { initialState } from './constants';

export const useGameStore = create(
  devtools(
    (set, get) => ({
      ...initialState,

      nextRound: () => {
        const nextRound = get().currentRound + 1;
        set({
          currentRound: nextRound,
          showScoring: false,
          view: 'objectives',
          selectedAgenda: undefined,
        });
      },

      nextObjective: () => {
        const nextRound = get().currentRound + 1;
        const variants = get().variants;
        let newS1 = get().availStage1;
        let newS2 = get().availStage2;
        if (variants.fourFourFour) {
          nextRound <= 3 ? (newS1 += 1) : (newS2 += 1);
        } else {
          nextRound <= 4 ? (newS1 += 1) : (newS2 += 1);
        }
        set({
          availStage1: newS1,
          availStage2: newS2,
          view: 'objectives',
          showScoring: false,
        });
      },

      agendaPhase: () => {
        const agenda = structuredClone(get().selectedAgenda);
        set({
          view: 'agenda',
          selectedAgenda: agenda !== undefined ? agenda : undefined,
          votes: {
            for: 0,
            against: 0,
            elect: [],
          },
        });
      },

      toggleGameVersion: (gameType) => {
        set({
          pok: gameType === 'pok',
        });
      },

      toggleDiscordant: () => {
        const togDiscord = get().showDiscordant;
        set({
          showDiscordant: !togDiscord,
        });
      },

      toggleScoring: () => {
        const togScore = get().showScoring;
        set({
          showScoring: !togScore,
        });
      },

      // set the id for the player who is claiming the custodians token (needed to handle the modal)
      setCustodianId: (id) => {
        set({
          custodianId: id,
        });
      },

      selectObjective: (name, i, type) => {
        const objectives = structuredClone(get().objectives);
        const list = type === 'classified' ? SECRETS : PUBLIC;
        const listIndex =
          type === 'classified' ? 2 : type === 'stage_1' ? 0 : 1;

        // get chosen objective based on name passed in name
        const objective = list.filter((obj) => obj.name === name)[0];
        // add the objective to list of chosen
        objectives[listIndex].list[i] = objective ? objective : undefined;
        set({
          objectives: objectives,
        });
      },

      selectAgenda: (agenda) => {
        let newAgenda = structuredClone(get().selectedAgenda);
        newAgenda = agenda;
        set({
          selectedAgenda: newAgenda,
          votes: {
            for: 0,
            against: 0,
            elect: [],
          },
        });
      },

      updateAgenda: (elect) => {
        let newAgenda = structuredClone(get().selectedAgenda);
        newAgenda.elect = elect;

        set({
          selectedAgenda: newAgenda,
        });
      },

      voteFor: (count) => {
        let newVotes = get().votes;
        newVotes.for += Number(count);
        set({
          votes: newVotes,
        });
      },

      voteAgainst: (count) => {
        let newVotes = get().votes;
        newVotes.against += Number(count);
        set({
          votes: newVotes,
        });
      },

      buildVoteElect: (options) => {
        let newVotes = get().votes;
        newVotes.elect = options;
        set({
          votes: newVotes,
        });
      },

      handleVoteElect: (type, id, key, value) => {
        // console.log('info:', type, id, key, value);
        let newVotes = get().votes;
        newVotes.elect[id][key] = key === 'votes' ? Number(value) : value;

        set({
          votes: newVotes,
        });
      },

      // bit of a hack in order to reload components when save deleted
      fireDeleteSaveEvent: () =>
        set({ deleteSaveEvent: !get().deleteSaveEvent }),

      gameClassified: () =>
        set({
          classifiedDocument: true,
          showModal: false,
          modalType: undefined,
        }),

      gameObsidian: () =>
        set({
          obsidian: true,
          showModal: false,
          modalType: undefined,
        }),

      setMiscPoints: () => {
        const newMisc = get().misc;
        set({
          misc: !newMisc,
        });
      },

      setGameStarted: (isGameStarted) => set({ gameStarted: isGameStarted }),

      setSaveKey: (key) => set({ saveKey: key }),

      toggleAlert: (alert) => set({ alert: alert }),

      setExpand: (arg) => {
        set({ expanded: arg });
      },

      setModalKey: (arg) => {
        set({ modalKey: arg });
      },

      toggleModal: (modal, arg) =>
        set({ modalType: modal, showModal: arg ? arg : !get().showModal }),

      repealClassified: () => {
        let newRepeal = get().classifiedRepealed;
        newRepeal = true;
        set({ classifiedRepealed: newRepeal });
      },

      repealCensure: () => {
        let newRepeal = get().censureRepealed;
        newRepeal = true;
        set({ censureRepealed: newRepeal });
      },

      discloseMiscount: () => {
        let newDisclose = get().miscountDisclosed;
        newDisclose = true;
        set({ miscountDisclosed: newDisclose });
      },

      setClassified: (arg) => {
        let newClassified = get().maxClassified;
        newClassified = arg;
        set({ maxClassified: newClassified });
      },

      incentive: (stage) => {
        let newMaxStage1 = get().maxStage1;
        let newMaxStage2 = get().maxStage2;
        let newAvailStage1 = get().availStage1;
        let newAvailStage2 = get().availStage2;
        if (stage === 1) {
          newMaxStage1 += 1;
          newAvailStage1 += 1;
        } else {
          newMaxStage2 += 1;
          newAvailStage2 += 1;
        }
        set({
          incentiveProgram: true,
          incentiveType: stage === 1 ? 'stage_1' : 'stage_2',
          maxStage1: newMaxStage1,
          maxStage2: newMaxStage2,
          availStage1: newAvailStage1,
          availStage2: newAvailStage2,
          showModal: false,
          modalType: undefined,
        });
      },

      toggleVariant: (arg) => {
        let newVariants = structuredClone(get().variants);
        let newMaxStage1 = get().maxStage1;
        let newMaxStage2 = get().maxStage2;
        let newAvail1 = get().availStage1;
        let newAvail2 = get().availStage2;
        let newMaxSecret = get().maxSecret;
        if (arg === 'fourFourFour') {
          if (newVariants.fourFourFour === false) {
            // turning on 4-4-4
            newMaxStage1 = 4;
            newMaxStage2 = 4;
            newMaxSecret = 4;
            newVariants.fourFourFour = true;
          } else {
            // turning off 4-4-4
            newMaxStage1 = 5;
            newMaxStage2 = 5;
            newMaxSecret = 3;
            newVariants.fourFourFour = false;
          }
          set({
            variants: newVariants,
            maxStage1: newMaxStage1,
            maxStage2: newMaxStage2,
            maxSecret: newMaxSecret,
          });
        } else {
          if (newVariants.redTape === false) {
            // turning on redtape
            newVariants.redTape = true;
          } else {
            // turning off redtape
            newVariants.redTape = false;
          }
          set({
            variants: newVariants,
            availStage1: newAvail1,
            availStage2: newAvail2,
          });
        }
      },

      setView: (view) => {
        set({ view: view });
      },

      playCard: (type, arg) => {
        set({
          [type]: arg,
          showModal: false,
          modalType: undefined,
        });
      },

      setBackground: (background) => {
        set({
          background: background,
        });
      },

      reset: () => {
        set(initialState);
      },
    }),
    {
      name: 'gameStore',
    }
  )
);
