import ScoreBalls from './ScoreBalls';

const PointTrackers = ({ total, scored }) => {
  return <ScoreBalls total={total} scored={scored} />;
};

export default PointTrackers;

PointTrackers.defaultProps = {
  total: 3,
  scored: 0,
};
