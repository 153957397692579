import { darken, transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '../constants/variables';

const Label = styled.label`
  color: ${colors.white};
  cursor: pointer;
  flex: 1;
  user-select: none;
  margin-left: 0.5rem;
  color: ${(props) =>
    props.checked ? colors.white : transparentize(0.5, colors.white)};
  transition: color 0.3s;
  &:hover {
    color: ${colors.white};
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
  &[disabled] {
    + span,
    ~ label {
      opacity: 0.5;
      pointer-events: none;
    }
    pointer-events: none;
  }

  &:checked {
    + span {
      background-color: ${colors.actionColor};
      border: solid 1px ${colors.actionColor};
    }
  }

  &:focus {
    outline: none;
    + span {
      box-shadow: 0 0 0 3px ${transparentize(0.5, colors.actionColor)};
    }
  }

  &:hover:not([disabled]) {
    cursor: pointer;
    &:checked {
      + span {
        background-color: ${darken(0.1, colors.actionColor)};
      }
    }
    &:not(:checked) {
      + span {
        background-color: ${transparentize(0.75, colors.white)};
      }
    }
  }
`;

const Small = styled.small`
  display: block;
  font-size: 0.85rem;
`;

const Check = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${transparentize(0.85, colors.white)};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.25rem;
  border: solid 1px transparent;
  transition: background-color 0.3s, border 0.3s, box-shadow 0.3s;
`;

const Checkbox = ({
  id,
  label,
  name,
  onChange,
  helpText,
  checked,
  ...props
}) => {
  return (
    <Wrapper>
      <Input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <Check>{checked && <img src="/check.svg" alt="" />}</Check>
      <Label htmlFor={id} checked={checked} {...props}>
        {label}
        {helpText && (
          <Small>
            <em>{helpText}</em>
          </Small>
        )}
      </Label>
    </Wrapper>
  );
};

export default Checkbox;
