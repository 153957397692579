import React, { useState } from 'react';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { usePlayerStore } from '../../stores/playerStore';
import Button from '../Button';
import FactionRadios from '../FactionRadios';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const ModalObsidian = () => {
  const [playerObsidian, players] = usePlayerStore((state) => [
    state.playerObsidian,
    state.players,
  ]);
  const gameObsidian = useGameStore((state) => state.gameObsidian);
  const list = players;
  const [selectedFaction, setSelection] = useState(list[0].id);
  return (
    <>
      <ModalBody>
        <p>
          Which faction gained <strong>THE OBSIDIAN</strong>?
        </p>
        <FactionRadios
          selected={selectedFaction}
          setSelection={setSelection}
          list={list}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            gameObsidian();
            playerObsidian(selectedFaction);
          }}
          size="sm"
        >
          Gain Relic
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalObsidian;
