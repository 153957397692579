import { transparentize } from 'polished';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  AreaSeries,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineMarkSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from 'react-vis';
import styled from 'styled-components';
import { colors } from '../constants/variables';
import { useGameStore } from '../stores/gameStore/gameStore';
import { usePlayerStore } from '../stores/playerStore';

let expected_points = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

const Div = styled.div`
  background-color: ${transparentize(0.95, colors.white)};
  padding: 0.5rem;
  height: fit-content;
  g {
    fill: white;
    &.rv-xy-plot__series--linemark {
      fill-opacity: 0;

      circle {
        fill-opacity: 1;
      }
    }
  }

  .rv-xy-plot__grid-lines__line {
    stroke-width: 1;
    stroke: ${transparentize(0.9, 'white')};
  }

  .rv-discrete-color-legend {
    &.horizontal {
      white-space: nowrap;
      display: flex;
      gap: 1rem;
    }
  }

  .rv-discrete-color-legend-item {
    &.horizontal {
      display: inline-flex;
      align-items: center;
    }
  }
`;

const Chart = ({ focused }) => {
  const [height, setHeight] = useState(900);

  // eslint-disable-next-line no-unused-vars
  const [currentRound, progression10, progression12, progression14] =
    useGameStore((state) => [
      state.currentRound,
      state.progression10,
      state.progression12,
      state.progression14,
    ]);
  const [players, scoreLimit, expectedRounds] = usePlayerStore((state) => [
    state.players,
    state.scoreLimit,
    state.expectedRounds,
  ]);

  // get the width of the window and reset the height of the chart
  useEffect(() => {
    if (window.matchMedia('(orientation: landscape)').matches) {
      if (window.innerHeight >= 1080) {
        setHeight(900);
      } else if (window.innerHeight >= 900 && window.innerHeight < 1080) {
        setHeight(720);
      } else if (window.innerHeight >= 768 && window.innerHeight < 900) {
        setHeight(590);
      } else if (window.innerHeight >= 700 && window.innerHeight < 768) {
        setHeight(520);
      } else if (window.innerHeight < 700) {
        setHeight(320);
      }
    } else {
      if (window.innerWidth > 550 && window.innerWidth <= 768) {
        setHeight(350);
      } else if (window.innerWidth <= 550) {
        setHeight(250);
      }
    }
  }, []);
  return (
    <Div>
      <FlexibleWidthXYPlot height={height} yDomain={[0, scoreLimit]}>
        <HorizontalGridLines tickTotal={scoreLimit} />
        <VerticalGridLines tickTotal={expectedRounds.length} />
        <XAxis
          title="Round"
          tickFormat={(v) => `${v}`}
          tickValues={expectedRounds}
        />
        <YAxis
          title="Points"
          tickFormat={(v) => `${v}`}
          tickValues={expected_points.slice(0, scoreLimit)}
        />

        <AreaSeries
          opacity={scoreLimit < 10 || scoreLimit > 14 ? 0 : 0.1}
          fill={'red'}
          stroke={'red'}
          data={
            scoreLimit <= 10
              ? progression10.slice(0, expectedRounds.length)
              : scoreLimit === 12
              ? progression12.slice(0, expectedRounds.length)
              : scoreLimit >= 14
              ? progression14.slice(0, expectedRounds.length)
              : undefined
          }
        />

        {players.map((player) => {
          if (player.faction.id === focused || focused === undefined) {
            return (
              <LineMarkSeries
                key={`chart_${player.faction.id}`}
                color={player.color}
                opacity={1}
                data={player.points.slice(0, currentRound)}
              />
            );
          } else {
            return null;
          }
        })}
      </FlexibleWidthXYPlot>
    </Div>
  );
};

export default Chart;
