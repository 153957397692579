import React, { useState } from 'react';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { usePlayerStore } from '../../stores/playerStore';
import Button from '../Button';
import FactionRadios from '../FactionRadios';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const ModalClassified = () => {
  const [playerClassified, players] = usePlayerStore((state) => [
    state.playerClassified,
    state.players,
  ]);
  const gameClassified = useGameStore((state) => state.gameClassified);
  const list = players.filter((player) => {
    return player.point_by_type.secret > 0;
  });
  const [selectedFaction, setSelection] = useState(
    list.length > 0 ? list[0].id : undefined
  );
  return (
    <>
      <ModalBody>
        <p>
          Which faction's <strong>CLASSIFIED DOCUMENT</strong> leaked?
        </p>
        {list.length ? (
          <FactionRadios
            selected={selectedFaction}
            setSelection={setSelection}
            list={list}
          />
        ) : (
          <p>
            <em>No faction currently qualifies.</em>
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            playerClassified(selectedFaction);
            gameClassified();
          }}
          size="sm"
          disabled={!list.length}
        >
          Leak Document
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalClassified;
