import React, { useState } from 'react';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { usePlayerStore } from '../../stores/playerStore';
import Button from '../Button';
import FactionRadios from '../FactionRadios';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const ModalEmphidia = () => {
  const [scorePoint, players] = usePlayerStore((state) => [
    state.scorePoint,
    state.players,
  ]);
  const [currentRound, toggleModal] = useGameStore((state) => [
    state.currentRound,
    state.toggleModal,
  ]);
  const list = players.filter((player) => {
    return player.point_by_type.emphidia === 0;
  });
  const [selectedFaction, setSelection] = useState(list[0].id);

  const handleScoring = () => {
    const scoringPlayer = players.filter((player) => {
      return player.point_by_type.emphidia > 0;
    });
    if (scoringPlayer.length > 0) {
      scorePoint(scoringPlayer[0].id, currentRound, 'emphidia', false);
    }
    scorePoint(selectedFaction, currentRound, 'emphidia', true);
  };
  return (
    <>
      <ModalBody>
        <p>
          Which faction <strong>HAS</strong> the{' '}
          <strong>CROWN of EMPHIDIA</strong>?
        </p>
        <FactionRadios
          selected={selectedFaction}
          setSelection={setSelection}
          list={list}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            handleScoring();
            toggleModal('emphidia', false);
          }}
          size="sm"
        >
          Gain Crown
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalEmphidia;
