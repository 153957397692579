import styled from 'styled-components';
import AgendaCard from './AgendaCard';
import { useGameStore } from '../../stores/gameStore';
import For from './For';
import Against from './Against';
import ElectBlock from './ElectBlock';
import { useState } from 'react';
import CovertSelect from './CovertSelect';
import { useEffect } from 'react';
import { breakpoints } from '../../constants/variables';
import Custom from './Custom';
import Button from '../Button';

const Div = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: ${(props) =>
    props.elect !== undefined ||
    (props.name === 'Covert Legislation' && props.covert !== 'for')
      ? '2fr 1fr'
      : 'repeat(3, 1fr)'};
  grid-gap: 1rem;

  @media only screen and (max-width: ${breakpoints.xs}) {
    grid-template-columns: 1fr;
  }
`;

const Container = styled.div`
  align-self: center;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;
  align-items: center;
`;

const AgendaSection = () => {
  const [covert, setCovert] = useState(undefined); // are we handling Covert Legislation?
  const [customAgenda, setCustom] = useState(false); // do we need to show the custom agenda block?

  const [selectedAgenda, updateAgenda] = useGameStore((state) => [
    state.selectedAgenda,
    state.updateAgenda,
  ]);
  const elect = selectedAgenda ? selectedAgenda.elect : undefined;

  const handleCovert = (type) => {
    setCovert(type);
    if (type !== 'for') {
      updateAgenda(type);
      setCovert(undefined);
    }
  };

  useEffect(() => {
    if (covert === 'for') {
      setCovert(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgenda]);
  const agendaName = selectedAgenda ? selectedAgenda.name : null;
  if (customAgenda) {
    return <Custom back={() => setCustom(false)} />;
  } else {
    return (
      <Div elect={elect} name={agendaName} covert={covert}>
        <Container>
          {((selectedAgenda && selectedAgenda.effect.for !== '') ||
            covert === 'for') && <For />}
          {elect !== undefined && <ElectBlock elect={elect} />}
          {selectedAgenda &&
            selectedAgenda.name === 'Covert Legislation' &&
            selectedAgenda.elect === undefined &&
            covert === undefined && (
              <CovertSelect handleCovert={handleCovert} />
            )}
        </Container>
        <Flex>
          <AgendaCard />
          <Button outline size="xs" onClick={() => setCustom(true)}>
            Custom Agenda
          </Button>
        </Flex>
        {elect === undefined && (
          <Container>
            {((selectedAgenda && selectedAgenda.effect.against !== '') ||
              covert === 'for') && <Against />}
          </Container>
        )}
      </Div>
    );
  }
};

export default AgendaSection;
