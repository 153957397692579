import React, { useState } from 'react';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { usePlayerStore } from '../../stores/playerStore';
import Button from '../Button';
import FactionRadios from '../FactionRadios';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const ModalCrown = () => {
  const [scorePoint, players] = usePlayerStore((state) => [
    state.scorePoint,
    state.players,
  ]);
  const [playCard, currentRound] = useGameStore((state) => [
    state.playCard,
    state.currentRound,
  ]);
  const list = players;
  const [selectedFaction, setSelection] = useState(list[0].id);
  return (
    <>
      <ModalBody>
        <p>
          Which faction has <strong>PURGED</strong> the <strong>CROWN</strong>?
        </p>
        <FactionRadios
          selected={selectedFaction}
          setSelection={setSelection}
          list={list}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            playCard('crownPurged', true);
            scorePoint(selectedFaction, currentRound, 'crown', true);
          }}
          size="sm"
        >
          Purge Crown
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalCrown;
