import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors, fonts } from '../constants/variables';

const Counter = styled.div`
  font-family: ${fonts.mono};
  display: flex;
  border: solid 1px ${transparentize(0.5, colors.actionColor)};
  border-radius: 0.25rem;
  overflow: hidden;
`;

const Block = styled.span`
  padding: 0.125rem 0.25rem;
  background-color: ${colors.black};
  color: ${colors.actionColor};

  &:not(:last-of-type) {
    border-right: solid 1px ${transparentize(0.5, colors.actionColor)};
  }
`;

const VisitCounter = ({ total, loading }) => {
  const stringArr = total.toString().split('');
  return (
    <Counter>
      {!loading ? (
        stringArr.map((char, index) => {
          return <Block key={index}>{char}</Block>;
        })
      ) : (
        <Block>fetching visitor count...</Block>
      )}
    </Counter>
  );
};

export default VisitCounter;
