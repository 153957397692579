import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

import { usePlayerStore } from '../../stores/playerStore';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { saveToLocal } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';

const P = styled.p`
  font-size: 1.25rem;
`;

const ModalQuit = () => {
  let navigate = useNavigate();
  const [resetGameState, toggleModal, saveKey] = useGameStore((state) => [
    state.reset,
    state.toggleModal,
    state.saveKey,
  ]);
  const [resetPlayerState] = usePlayerStore((state) => [state.reset]);

  const handleSave = () => {
    toggleModal();
    const playerState = usePlayerStore.getState();
    const gameState = useGameStore.getState();
    const saveState = { gameState, playerState };
    saveToLocal(saveState, saveKey);
    resetGameState();
    resetPlayerState();
    navigate('/', { replace: true });
  };

  const handleQuit = () => {
    resetPlayerState();
    resetGameState();
    navigate('/', { replace: true });
  };

  return (
    <>
      <ModalBody>
        <P>Would you like to save this game before you quit?</P>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={handleQuit} size="sm">
          No, Quit
        </Button>
        <Button onClick={handleSave} size="sm">
          Save and Quit
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalQuit;
