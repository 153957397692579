import { css } from 'styled-components';
import { breakpoints, fonts } from '../constants/variables';

export const typographyCSS = css`
  body {
    font-family: ${fonts.standard};
    font-weight: 400;
  }

  input {
    font-family: ${fonts.standard};
  }

  h1 {
    font-family: ${fonts.fancy};
    font-weight: 300;
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: ${breakpoints.sm}) {
      font-size: 1rem;
    }
  }

  h2 {
    margin: 0 0 0.25rem;
    font-size: 0.85rem;
    font-weight: 400;
    white-space: nowrap;
    line-height: 1;
    font-family: ${fonts.standard};
  }

  h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
  }

  p {
    margin: 0 0 1rem;
  }

  button {
    font-family: ${fonts.standard};
  }
`;
