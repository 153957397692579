import styled from 'styled-components';
import { breakpoints } from '../../constants/variables';

const StyledSection = styled.section`
  padding: 1.5rem 1rem;

  @media only screen and (max-width: ${breakpoints.xs}) {
    flex: 1;
  }
`;

const ModalBody = (props) => {
  const { children } = props;
  return <StyledSection aria-label="Modal Content">{children}</StyledSection>;
};

export default ModalBody;
