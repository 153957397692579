import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

import { usePlayerStore } from '../../stores/playerStore';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { saveToLocal } from '../../utils/helpers';

const P = styled.p`
  font-size: 1.25rem;
`;

const ModalSave = () => {
  const [toggleModal, toggleAlert, saveKey] = useGameStore((state) => [
    state.toggleModal,
    state.toggleAlert,
    state.saveKey,
  ]);

  const handleSave = () => {
    toggleModal();
    const playerState = usePlayerStore.getState();
    const gameState = useGameStore.getState();
    const saveState = { gameState, playerState };
    saveToLocal(saveState, saveKey);
    toggleAlert({
      type: 'success',
      text: 'Game was saved successfully',
      show: true,
    });
  };

  return (
    <>
      <ModalBody>
        <P>Would you like to save this game?</P>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSave} size="sm">
          Save Game
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalSave;
