import { useState } from 'react';
import { useGameStore } from '../stores/gameStore/gameStore';
import { useOutsideClick } from '../utils/helpers';
import Dropdown from './Dropdown';
import TextButton from './TextButton';
import ActionIcon from './Graphics/ActionIcon';

const ActionCards = () => {
  const [show, setShow] = useState(false);
  const [toggleModal] = useGameStore((state) => [state.toggleModal]);

  const handleClickOutside = () => {
    setShow(false);
  };

  const ref = useOutsideClick(handleClickOutside);
  return (
    <Dropdown
      innerRef={ref}
      show={show}
      toggle={() => setShow(!show)}
      label="Action Cards"
      posX="left"
      mobileIcon={<ActionIcon />}
    >
      <TextButton
        lg
        onClick={() => {
          toggleModal('rider');
          setShow(!show);
        }}
      >
        Imperial Rider
      </TextButton>
    </Dropdown>
  );
};

export default ActionCards;
