import React from 'react';
import styled from 'styled-components';
import Checkbox from '../Checkbox';

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Legend = styled.legend`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

const FactionCheckboxes = ({ onChange, checked, list }) => {
  return (
    <Fieldset>
      <Legend>Select one or more Factions</Legend>
      {list.map((player, index) => {
        return (
          <Checkbox
            key={`${index}_${player.faction.name}`}
            id={`${index}_${player.faction.name}`}
            onChange={() => onChange(player.id)}
            checked={checked.includes(player.id)}
            label={
              player.name !== ''
                ? `${player.name}: ${player.faction.name}`
                : player.faction.name
            }
          />
        );
      })}
    </Fieldset>
  );
};

export default FactionCheckboxes;
