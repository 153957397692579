import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '../constants/variables';

const Div = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const StyledLabel = styled.label`
  margin: 0;
  line-height: 1.4;
  color: ${transparentize(0.5, colors.white)};
  transition: color 0.3s;
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  margin: 0;

  &:not([disabled]) {
    cursor: pointer;

    ~ label {
      cursor: pointer;
    }
  }

  &[disabled] {
    + span,
    ~ label {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &:checked {
    + span {
      border: solid 6px ${colors.actionColor};
      background-color: ${colors.white};
    }

    ~ label {
      color: inherit;
    }
  }

  &:hover:not([disabled]):not(:checked) {
    + span {
      background-color: ${transparentize(0.5, colors.white)};
    }
    ~ label {
      color: ${transparentize(0.25, colors.white)};
    }
  }

  &:focus {
    + span {
      box-shadow: 0 0 0 3px ${transparentize(0.5, colors.actionColor)};
    }
  }
`;

const Span = styled.span`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: solid 6px transparent;
  background-color: ${transparentize(0.75, colors.white)};
  transition: border 0.3s, background-color 0.3s, box-shadow 0.3s;
`;

const Radio = ({ id, label, checked, name, onChange, disabled }) => {
  return (
    <Div>
      <StyledInput
        name={name}
        id={id}
        type="radio"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <Span />
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
    </Div>
  );
};

export default Radio;
