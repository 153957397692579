import { transparentize } from 'polished';
import React, { useEffect /*, { useEffect }*/ } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, colors } from '../constants/variables';
import ModalClassified from './Modal/ModalClassified';
import Modal from './Modal/Modal';
import ModalObsidian from './Modal/ModalObsidian';
import ModalSave from './Modal/ModalSave';
import { useGameStore } from '../stores/gameStore/gameStore';
import ModalIncentive from './Modal/ModalIncentive';
import ModalWinner from './Modal/ModalWinner';
import ModalQuit from './Modal/ModalQuit';
import ModalImperial from './Modal/ModalImperial';
import ModalMutiny from './Modal/ModalMutiny';
import ModalSeed from './Modal/ModalSeed';
import ModalCensure from './Modal/ModalCensure';
import ModalCrown from './Modal/ModalCrown';
import ModalShard from './Modal/ModalShard';
import ModalDelete from './Modal/ModelDelete';
import { usePlayerStore } from '../stores/playerStore';
import ModalIxth from './Modal/ModalIxth';
import ModalEmphidia from './Modal/ModalEmphidia';
import ModalCustodians from './Modal/ModalCustodians';
//import { usePlayerStore } from "../stores/playerStore";

const Container = styled.div`
  background-color: ${transparentize(0.25, colors.black)};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  border: solid 1px ${colors.black};
  ${(props) => {
    if (!props.game_started) {
      return css`
        min-height: calc(100vh - 3.2em);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 3rem;
        padding: 4rem 1rem;
      `;
    } else {
      return css`
        padding: 0.5rem;
        min-height: calc(100vh - 1rem);
      `;
    }
  }}

  @media only screen and (max-width: ${breakpoints.sm}) {
    ${(props) => {
      if (!props.game_started) {
        return css`
          padding: 4rem 0.25rem;
          gap: 2rem;
        `;
      }
    }}
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    gap: 1rem;
  }
`;

const Panel = (props) => {
  const [showModal, modalType, gameStarted, toggleModal] = useGameStore(
    (state) => [
      state.showModal,
      state.modalType,
      state.gameStarted,
      state.toggleModal,
    ]
  );
  const winner = usePlayerStore((state) => state.winner);
  useEffect(() => {
    if (Object.keys(winner).length > 0) {
      toggleModal('winner');
    }
  }, [toggleModal, winner]);
  return (
    <>
      <Container game_started={gameStarted}>{props.children}</Container>
      {showModal && (
        <Modal
          title={
            modalType === 'classified'
              ? 'Classified Document Leaks'
              : modalType === 'custodians'
              ? 'Claim Custodians Token'
              : modalType === 'obsidian'
              ? 'Obsidian Relic Gained'
              : modalType === 'save'
              ? 'Save Game'
              : modalType === 'incentive'
              ? 'Incentive Program'
              : modalType === 'winner'
              ? 'New Galactic Emperor'
              : modalType === 'quit'
              ? 'Quit Game'
              : modalType === 'rider'
              ? 'Imperial Rider'
              : modalType === 'mutiny'
              ? 'Mutiny'
              : modalType === 'seed'
              ? 'Seed of an Empire'
              : modalType === 'censure'
              ? 'Political Censure'
              : modalType === 'crown'
              ? 'Crown of Emphidia'
              : modalType === 'ixth'
              ? 'Holy Planet of Ixth'
              : modalType === 'emphidia'
              ? 'Crown of Emphidia'
              : modalType === 'shard'
              ? 'Shard of the Throne'
              : modalType === 'delete'
              ? 'Delete Game'
              : 'Unselected'
          }
        >
          {modalType === 'custodians' && <ModalCustodians />}
          {modalType === 'classified' && <ModalClassified />}
          {modalType === 'obsidian' && <ModalObsidian />}
          {modalType === 'save' && <ModalSave />}
          {modalType === 'quit' && <ModalQuit />}
          {modalType === 'incentive' && <ModalIncentive />}
          {modalType === 'winner' && <ModalWinner />}
          {modalType === 'rider' && <ModalImperial />}
          {modalType === 'mutiny' && <ModalMutiny />}
          {modalType === 'seed' && <ModalSeed />}
          {modalType === 'censure' && <ModalCensure />}
          {modalType === 'crown' && <ModalCrown />}
          {modalType === 'shard' && <ModalShard />}
          {modalType === 'ixth' && <ModalIxth />}
          {modalType === 'emphidia' && <ModalEmphidia />}
          {modalType === 'delete' && <ModalDelete />}
        </Modal>
      )}
    </>
  );
};

export default Panel;
