import { useState } from 'react';
import styled from 'styled-components';
import { useGameStore } from '../../stores/gameStore/gameStore';
import Button from '../Button';
import Radio from '../Radio';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Legend = styled.legend`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

const ModalIncentive = () => {
  const incentive = useGameStore((state) => state.incentive);
  const [stage, setStage] = useState(1);
  return (
    <>
      <ModalBody>
        <p>
          Which Stage is being <strong>INCENTIVIZED</strong>?
        </p>
        <Fieldset>
          <Legend>Choose a Stage</Legend>
          <Radio
            id="stage_1"
            label="Stage 1"
            name="stages"
            checked={stage === 1}
            onChange={() => setStage(1)}
          />
          <Radio
            id="stage_2"
            label="Stage 2"
            name="stages"
            checked={stage === 2}
            onChange={() => setStage(2)}
          />
        </Fieldset>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            incentive(stage);
          }}
          size="sm"
        >
          Incentivize
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalIncentive;
