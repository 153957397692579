import { lighten, transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { colors, fonts } from '../constants/variables';
import { useGameStore } from '../stores/gameStore';

const Holder = styled.div`
  position: fixed;
  top: 3rem;
  left: 50%;
  width: 30vw;
  max-width: 100%;
  min-width: 18.75rem;
  z-index: 100;
  pointer-events: none;
  transform: translateX(-50%);
`;

const Box = styled.div`
  margin: 0 auto;
  width: fit-content;
  padding: 1rem 1rem 0.8rem;
  border-radius: 0;
  font-family: ${fonts.mono};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: ${(props) =>
    props.show ? 'all 0.3s linear' : 'all 0.3s linear 2s'};
  ${(props) => {
    switch (props.type) {
      case 'info':
        return css`
          background-color: ${transparentize(0.05, colors.midGray)};
          border: solid 1px ${lighten(0.2, colors.midGray)};
          box-shadow: 0 0 5px ${colors.midGray};
        `;
      case 'success':
        return css`
          background-color: ${transparentize(0.05, colors.success)};
          border: solid 1px ${lighten(0.2, colors.success)};
          box-shadow: 0 0 5px ${colors.success};
        `;
      case 'error':
        return css`
          background-color: ${transparentize(0.05, colors.error)};
          border: solid 1px ${lighten(0.2, colors.error)};
          box-shadow: 0 0 5px ${colors.error};
        `;
      default:
        return css``;
    }
  }}
`;

const Alert = () => {
  const [alert, toggleAlert] = useGameStore((state) => [
    state.alert,
    state.toggleAlert,
  ]);
  return (
    <Holder>
      <Box
        type={alert.type}
        show={alert.show}
        onTransitionEnd={() =>
          toggleAlert({ type: alert.type, text: alert.text, show: false })
        }
      >
        {alert.text}
      </Box>
    </Holder>
  );
};

export default Alert;
