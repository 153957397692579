import styled from 'styled-components';
import { breakpoints } from '../../constants/variables';
import { useGameStore } from '../../stores/gameStore';
import Toggle from '../Toggle';
import ObjectiveCard from './ObjectiveCard';

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.count === 1 ? (props.isFour ? 4 : 5) : props.count)},
    1fr
  );
  grid-gap: 0.5rem;
  flex: 1;

  @media only screen and (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const H1 = styled.h1`
  margin: 0;
`;

const ObjectiveBlock = ({
  count,
  options,
  type,
  incentiveType,
  legend,
  maxAllowed,
}) => {
  const [variants, showScoring, toggleScoring] = useGameStore((state) => [
    state.variants,
    state.showScoring,
    state.toggleScoring,
  ]);
  const incentivized = type === incentiveType;

  return (
    <Section aria-labelledby="legend">
      <Flex>
        <H1 id="legend">{legend}</H1>
        {type === 'stage_1' && (
          <Toggle
            label="Score Objectives"
            toggled={showScoring}
            onClick={() => toggleScoring()}
          />
        )}
      </Flex>
      <Grid count={count} isFour={variants.fourFourFour}>
        {[...Array(type === 'classified' ? maxAllowed : count)].map(
          (value, index) => (
            <ObjectiveCard
              key={type + '_' + index}
              options={options}
              index={index}
              type={type}
              maxAllowed={maxAllowed}
              count={count}
              showScoring={showScoring}
              incentivized={incentivized}
              disabled={
                !variants.redTape &&
                (incentivized
                  ? index !== count - 1 && maxAllowed - 1 <= index
                  : maxAllowed - 1 < index)
              }
            />
          )
        )}
      </Grid>
    </Section>
  );
};

export default ObjectiveBlock;
