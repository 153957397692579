import { useEffect, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useGameStore } from '../../stores/gameStore';
import { usePlayerStore } from '../../stores/playerStore';
import Button from '../Button';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const style = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: -1,
};

const ModalWinner = () => {
  const [fire, setFire] = useState(false);
  const [winner] = usePlayerStore((state) => [state.winner]);

  const [toggleModal] = useGameStore((state) => [state.toggleModal]);

  useEffect(() => {
    setFire(true);
  }, []);
  return (
    <>
      <ModalBody>
        <h1>{winner.faction.name} is the game winner!</h1>
      </ModalBody>
      <ModalFooter hideCancel>
        <Button outline onClick={() => toggleModal()} size="sm">
          Ok
        </Button>
        <Button size="sm" onClick={() => toggleModal('quit', true)}>
          Quit Game
        </Button>
      </ModalFooter>
      <ReactCanvasConfetti
        style={style}
        fire={fire}
        spread={360}
        ticks={50}
        gravity={0}
        decay={0.94}
        startVelocity={30}
        colors={['FFE400', 'FFBD00', 'E89400']}
      />
    </>
  );
};

export default ModalWinner;
