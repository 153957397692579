import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey:
    'BJzZ4W2gFSU0wHjC5zMuAoJMVCHh4aYDCWeGiH6KSuEfqAUuDVVv3RgM1_ZvOB31BjOoJEYPcs5JXc9qsyLmcfU',
  authDomain: 'https://ti4score.com/',
  projectId: 'ti-score',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// import this anywhere to use the firestore db
export const db = getFirestore(app);
