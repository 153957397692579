const AgendaIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.5 133.5"
      width="24px"
      height="24px"
    >
      <g>
        <circle cx="65.3" cy="66.7" r="32.2" />
        <g>
          <polygon points="43,6.1 51,1.5 51,1.5" />
          <polygon points="51,1.5 51,1.5 43,6.1" />
          <polygon points="88.6,6.7 79.6,1.5 79.6,1.5" />
          <polygon points="43,6.1 51,1.5 51,1.5" />
          <polygon points="87.6,127.3 79.5,132 79.5,131.9" />
          <polygon points="51,131.9 51,132 42,126.8" />
          <polygon points="87.6,127.3 79.5,132 79.5,131.9" />
          <path d="M16.5,49.5V84c-5.2-1.9-10.9-0.7-14.9,3V46.5c2.6,2.4,6.1,3.9,9.9,3.9C13.3,50.4,15,50.1,16.5,49.5z" />
          <path
            d="M51,131.9l-9-5.2l-0.4-0.2l-25.7-14.8c3.4-1,6.4-3.3,8.3-6.6c0.9-1.5,1.5-3.1,1.7-4.8l29.9,17.3
			C51.6,121.1,49.8,126.7,51,131.9z"
          />
          <path
            d="M114.6,111.7l-27,15.6l-8,4.6c0.8-3.5,0.3-7.2-1.6-10.5c-0.9-1.5-2-2.8-3.3-3.9l29.9-17.3
			C105.5,105.7,109.4,110.1,114.6,111.7z"
          />
          <polygon points="87.6,127.3 79.5,132 79.5,131.9" />
          <path
            d="M55.8,15.9L26,33.1c-0.9-5.4-4.8-9.8-10-11.4L43,6.1l8-4.6C50.2,5,50.7,8.7,52.6,12
			C53.4,13.6,54.5,14.9,55.8,15.9z"
          />
          <path
            d="M114.6,21.8c-3.4,1-6.4,3.3-8.3,6.6c-0.9,1.5-1.5,3.1-1.7,4.8L74.7,15.9c4.2-3.5,6-9.1,4.9-14.4l9,5.2
			l0.4,0.2L114.6,21.8z"
          />
          <path d="M128.9,46.5V87c-2.6-2.4-6.1-3.9-9.9-3.9c-1.8,0-3.4,0.3-5,0.9V49.5C119.2,51.4,124.9,50.1,128.9,46.5z" />
        </g>
      </g>
    </svg>
  );
};

export default AgendaIcon;
