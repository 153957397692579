import { css } from 'styled-components';
import { breakpoints, colors, fonts } from '../constants/variables';

export const globalCSS = css`
  body,
  html {
    margin: 0;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  body {
    user-select: none;
    padding: 0.5rem;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-x: hidden;
    color: ${colors.white};
    background-color: ${colors.cardBack};
    font-family: ${fonts.standard};

    @media only screen and (max-width: ${breakpoints.lg}) {
      padding: 0.5rem;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
      padding: 0;
    }
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }

  a {
    color: ${colors.white};
  }

  .react-tooltip-lite {
    width: fit-content !important;
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`;
