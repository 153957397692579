import {
  getInitialChosenColorsHash,
  getInitialFactionsHash,
} from '../../utils/helpers';

export const initialState = {
  players: [],
  chosenFactions: getInitialFactionsHash(),
  chosenColors: getInitialChosenColorsHash(),
  scoreLimit: 10,
  expectedRounds: [1, 2, 3, 4, 5],
  playerCount: 6,
  winner: {},
};
