import React, { useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from '../constants/variables';
import { usePlayerStore } from '../stores/playerStore';
import Slider from './Slider';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30em;
  max-width: 100%;
`;

const Players = styled.h2`
  font-size: 2rem;
  margin-top: 1rem;
`;

const Numbers = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  width: 108%;

  @media only screen and (max-width: ${breakpoints.xs}) {
    width: 104%;
  }
`;

const Num = styled.button`
  display: block;
  text-align: center;
  color: ${(props) => (props.active ? colors.actionColor : colors.white)};
  font-weight: ${(props) => (props.active ? 600 : 400)};
  background-color: transparent;
  padding: 0.5rem 0;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s;
`;

const PlayerCounter = () => {
  const playerArr = [2, 3, 4, 5, 6, 7, 8];
  const [playerCount, initPlayers] = usePlayerStore((state) => [
    state.playerCount,
    state.initPlayers,
  ]);

  useEffect(() => {
    initPlayers(playerCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section aria-labelledby="players">
      <h1 id="players">How many people are playing?</h1>
      <Numbers columns={playerArr.length}>
        {playerArr.map((num) => {
          return (
            <Num
              key={num}
              aria-hidden={true}
              tabIndex={-1}
              onClick={() => initPlayers(num)}
              active={num === playerCount}
            >
              {num}
            </Num>
          );
        })}
      </Numbers>
      <Slider
        min={playerArr[0]}
        max={playerArr.slice(-1)}
        count={playerCount}
        onChange={initPlayers}
      />
      <Players>{playerCount} Players</Players>
    </Section>
  );
};

export default PlayerCounter;
