import styled, { css } from 'styled-components';
import CovertSelect from './CovertSelect';
import Button from '../Button';
import { breakpoints, colors, fonts } from '../../constants/variables';
import { useState } from 'react';
import { mix, transparentize } from 'polished';
import Radio from '../Radio';
import For from './For';
import Against from './Against';
import ElectBlock from './ElectBlock';
import TextButton from '../TextButton';

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$agendaType === 'for' ? 'repeat(3, 1fr)' : '2fr 1fr'};
  align-items: center;
  grid-gap: 1rem;
  flex: 1;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 1rem;
`;

const CardFlex = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

// the main agenda card container
const Card = styled.div`
  min-height: 26em;
  width: 20em;
  color: ${colors.white};
  text-align: center;
  border: solid 2px ${colors.agenda};
  ${(props) => {
    return css`
      background-color: ${colors.cardBack};
    `;
  }}
  padding: 1rem 1rem 2.25rem;
  font-family: ${fonts.standard};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  transition: box-shadow 0.3s, opacity 0.3s;

  @media only screen and (max-width: ${breakpoints.lg}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: flex-start;
    position: relative;
    min-height: initial;
    height: auto;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    padding: 0.5rem 0.5rem 2.25rem;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  font-family: ${fonts.standard};
  color: ${mix(0.25, colors.agenda, colors.white)};
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
  background-color: ${colors.cardBack};
  border: solid 1px ${transparentize(0.75, colors.agenda)};
  border-radius: 0.25rem;
`;

const H2 = styled.h2`
  text-transform: capitalize;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  color: ${(props) => (props.type === 'law' ? colors.law : colors.directive)};
  text-align: center;
`;

const Text = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  z-index: 2;
  background-color: ${colors.white};
  padding: 1rem;
  border-radius: 0.25rem;

  @media only screen and (max-width: ${breakpoints.lg}) {
    justify-content: flex-start;
    gap: 0.75rem;
  }
`;

const P = styled.p`
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  text-align: left;
  color: ${colors.black};

  @media only screen and (max-width: ${breakpoints.lg}) {
    font-size: 1.25rem;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`;

const TextArea = styled.textarea`
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  text-align: left;
  color: ${colors.black};
  flex: 1;
  font-family: ${fonts.standard};
  border: solid 1px ${transparentize(0.75, colors.black)};
  border-radius: 0.25rem;
`;

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Legend = styled.legend`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

const Custom = ({ back }) => {
  const [type, setType] = useState('law');
  const [name, setName] = useState('');
  const [agendaType, setAgendaType] = useState('');
  const [agendaText, setText] = useState('Customize the Agenda text...');

  const handleReset = () => {
    setType('law');
    setName('');
    setAgendaType('');
    setText('Customize the Agenda text...');
  };

  // set the type of agenda
  const handleSetType = (type) => {
    setAgendaType(type);
  };
  return (
    <Grid $agendaType={agendaType}>
      {agendaType === 'for' ? (
        <For />
      ) : (
        <Flex>
          {agendaType === '' && (
            <>
              <div>
                <h1>Law or Directive?</h1>
                <Fieldset>
                  <Legend>
                    Choose whether the Agenda is a Law or Directive
                  </Legend>
                  <Radio
                    label="Law"
                    id="law"
                    name="agenda_type"
                    checked={type === 'law'}
                    onChange={() => setType('law')}
                  />
                  <Radio
                    label="Directive"
                    id="directive"
                    name="agenda_type"
                    checked={type === 'directive'}
                    onChange={() => setType('directive')}
                  />
                </Fieldset>
              </div>
              <CovertSelect
                label="Choose the Custom Agenda's Outcome Type"
                handleCovert={handleSetType}
              />
            </>
          )}
          {agendaType !== 'for' && agendaType !== '' && (
            <ElectBlock elect={agendaType} />
          )}
        </Flex>
      )}
      <Div>
        <Card>
          <CardFlex>
            <Heading>
              <Input
                placeholder="Add a Title"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <H2 type={type}>{type}</H2>
            </Heading>
            <Text>
              {agendaType !== 'for' && agendaType !== '' && (
                <P style={{ textAlign: 'center' }}>
                  <strong>Elect {agendaType}</strong>
                </P>
              )}
              <TextArea
                onClick={(e) => e.target.select()}
                onFocus={(e) => e.target.select()}
                onChange={(e) => setText(e.target.value)}
                value={agendaText}
                placeholder="Begin typing in this box to set the custom text for your Agenda."
              />
            </Text>
          </CardFlex>
        </Card>
        <Button outline size="xs" onClick={back}>
          Return to the Official Agendas
        </Button>
        <TextButton onClick={handleReset}>Reset Custom Agenda</TextButton>
      </Div>
      {agendaType === 'for' && <Against />}
    </Grid>
  );
};

export default Custom;
