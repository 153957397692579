import { useState } from 'react';
import styled from 'styled-components';
import { useGameStore } from '../stores/gameStore/gameStore';
import { usePlayerStore } from '../stores/playerStore';
import { useOutsideClick } from '../utils/helpers';
import Dropdown from './Dropdown';
import TextButton from './TextButton';
import AgendaIcon from './Graphics/AgendaIcon';

const Flex = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;
`;

const Span = styled.span`
  margin-top: 0.5rem;
  opacity: 0.5;
  line-height: 1.1;
`;

const Agendas = () => {
  const [show, setShow] = useState(false);
  const [
    classifiedDocument,
    classifiedRepealed,
    censureRepealed,
    repealClassified,
    repealCensure,
    toggleModal,
    incentiveProgram,
    incentiveType,
    mutiny,
    seedOfEmpire,
    politicalCensure,
    currentRound,
    discloseMiscount,
    miscountDisclosed,
    setClassified,
    pok,
  ] = useGameStore((state) => [
    state.classifiedDocument,
    state.classifiedRepealed,
    state.censureRepealed,
    state.repealClassified,
    state.repealCensure,
    state.toggleModal,
    state.incentiveProgram,
    state.incentiveType,
    state.mutiny,
    state.seedOfEmpire,
    state.politicalCensure,
    state.currentRound,
    state.discloseMiscount,
    state.miscountDisclosed,
    state.setClassified,
    state.pok,
  ]);

  const [players, scorePoint] = usePlayerStore((state) => [
    state.players,
    state.scorePoint,
  ]);

  const undoCensure = () => {
    const censurePlayer = players.filter((player) => {
      return player.point_by_type.censure > 0;
    });
    if (censurePlayer.length > 0) {
      scorePoint(censurePlayer[0].id, currentRound, 'censure', false);
    }
  };

  const handleClickOutside = () => {
    setShow(false);
  };

  const ref = useOutsideClick(handleClickOutside);
  return (
    <Dropdown
      innerRef={ref}
      show={show}
      toggle={() => setShow(!show)}
      label="Agendas"
      posX="left"
      mobileIcon={<AgendaIcon />}
    >
      <TextButton
        onClick={() => {
          toggleModal('incentive');
          setShow(!show);
        }}
        disabled={incentiveProgram}
        lg
      >
        {`Incentiv${
          incentiveProgram
            ? `ized (${incentiveType === 'stage_1' ? 'Stage 1' : 'Stage 2'})`
            : 'e Program'
        }`}
      </TextButton>
      <Flex>
        <TextButton
          onClick={() => {
            toggleModal('classified');
            setShow(!show);
          }}
          disabled={classifiedDocument}
          repealed={classifiedRepealed}
          lg
        >
          {classifiedDocument ? 'Document Leaked' : 'Classified Document Leaks'}
        </TextButton>
        {classifiedDocument && (
          <>
            <TextButton
              onClick={() => {
                repealClassified();
                setShow(!show);
              }}
              disabled={classifiedRepealed}
              lg
            >
              {`↳ Repeal${classifiedRepealed ? 'ed' : ''} Law`}
            </TextButton>
            {!miscountDisclosed && (
              <TextButton
                onClick={() => {
                  toggleModal('classified');
                  discloseMiscount();
                  setClassified(2);
                  setShow(!show);
                }}
                disabled={classifiedRepealed || miscountDisclosed}
                lg
              >
                ↳ {!miscountDisclosed && !classifiedRepealed ? 'Play ' : ''}
                "Miscount Disclosed"
              </TextButton>
            )}
          </>
        )}
      </Flex>
      <TextButton
        lg
        onClick={() => {
          toggleModal('mutiny');
          setShow(!show);
        }}
        disabled={mutiny}
      >
        Mutiny
      </TextButton>
      <TextButton
        lg
        onClick={() => {
          toggleModal('seed');
          setShow(!show);
        }}
        disabled={seedOfEmpire}
      >
        Seed of an Empire
      </TextButton>
      {pok && (
        <Flex>
          <TextButton
            lg
            onClick={() => {
              toggleModal('censure');
              setShow(!show);
            }}
            disabled={politicalCensure}
            repealed={censureRepealed}
          >
            Political Censure
          </TextButton>
          {politicalCensure && (
            <>
              <TextButton
                onClick={() => {
                  repealCensure();
                  undoCensure();
                  setShow(!show);
                }}
                disabled={censureRepealed}
                lg
              >
                {`↳ Repeal${censureRepealed ? 'ed' : ''} Law`}
              </TextButton>
              {!miscountDisclosed && (
                <TextButton
                  onClick={() => {
                    toggleModal('censure');
                    undoCensure();
                    discloseMiscount();
                    setShow(!show);
                  }}
                  lg
                  disabled={censureRepealed || miscountDisclosed}
                >
                  ↳ {!miscountDisclosed && !censureRepealed ? 'Play ' : ''}
                  "Miscount Disclosed"
                </TextButton>
              )}
            </>
          )}
          {miscountDisclosed && (
            <Span>
              <small>
                <em>Miscount Disclosed on a Law</em>
              </small>
            </Span>
          )}
        </Flex>
      )}
      {!pok && (
        <>
          <TextButton
            lg
            onClick={() => {
              toggleModal('ixth');
              setShow(!show);
            }}
          >
            Holy Planet of Ixth
          </TextButton>
          <TextButton
            lg
            onClick={() => {
              toggleModal('shard');
              setShow(!show);
            }}
          >
            Shard of the Throne
          </TextButton>
          <TextButton
            lg
            onClick={() => {
              toggleModal('emphidia');
              setShow(!show);
            }}
          >
            The Crown of Emphidia
          </TextButton>
        </>
      )}
    </Dropdown>
  );
};

export default Agendas;
