import styled from 'styled-components';
import { breakpoints } from '../constants/variables';
import { useGameStore } from '../stores/gameStore';
import { usePlayerStore } from '../stores/playerStore';
import Checkbox from './Checkbox';

const Section = styled.section`
  @media only screen and (max-width: ${breakpoints.xs}) {
    width: 100%;
  }
`;

const H1 = styled.h1`
  text-align: center;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const VariantRules = () => {
  const [toggleVariant, variants] = useGameStore((state) => [
    state.toggleVariant,
    state.variants,
  ]);
  const [setScoreLimit] = usePlayerStore((state) => [state.setScoreLimit]);

  const toggleFour = () => {
    if (variants.fourFourFour) {
      setScoreLimit(10);
    } else {
      setScoreLimit(12);
    }
    toggleVariant('fourFourFour');
  };
  return (
    <Section aria-labelledby="variant">
      <H1 id="variant">Do you wish to add variant rules?</H1>
      <Group>
        <Checkbox
          id="444"
          checked={variants.fourFourFour}
          onChange={toggleFour}
          label="4-4-4"
          helpText="4 Stage 1, 4 Stage 2, 4 Secrets"
          name="variant_444"
        />
        <Checkbox
          id="redtape"
          checked={variants.redTape}
          onChange={() => toggleVariant('redTape')}
          label="Red Tape"
          helpText="All objectives revealed, unlocked as normal"
          name="variant_redtape"
        />
      </Group>
    </Section>
  );
};

export default VariantRules;
