import styled from 'styled-components';
import { useGameStore } from '../stores/gameStore';
import Button from './Button';
import { colors } from '../constants/variables';

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
`;

const Li = styled.li`
  margin: 0;
  padding: 0;
`;

const LeftButton = styled(Button)`
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: ${(props) =>
    props.active ? 'solid 1px transparent' : `solid 1px ${colors.actionColor}`};
  margin: 0;
`;

const MiddleButton = styled(Button)`
  border-radius: 0;
  border-left: none;
  border-right: none;
  margin: 0;
`;

const RightButton = styled(Button)`
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: ${(props) =>
    props.active ? 'solid 1px transparent' : `solid 1px ${colors.actionColor}`};
  margin: 0;
`;

const Tabs = () => {
  const [view, setView] = useGameStore((state) => [state.view, state.setView]);
  return (
    <Ul role="nav">
      <Li>
        <LeftButton
          active={view === 'objectives'}
          onClick={() => setView('objectives')}
          outline
          size="xs"
        >
          Objectives
        </LeftButton>
      </Li>
      <Li>
        <MiddleButton
          active={view === 'chart'}
          onClick={() => setView('chart')}
          outline
          size="xs"
        >
          Score
        </MiddleButton>
      </Li>
      <Li>
        <RightButton
          active={view === 'agenda'}
          onClick={() => setView('agenda')}
          outline
          size="xs"
        >
          Agenda
        </RightButton>
      </Li>
    </Ul>
  );
};

export default Tabs;
