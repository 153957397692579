import { useState } from 'react';
import { useGameStore } from '../stores/gameStore';
import { useOutsideClick } from '../utils/helpers';
import Dropdown from './Dropdown';
import TextButton from './TextButton';

const GameNav = () => {
  const [show, setShow] = useState(false);
  const [toggleModal] = useGameStore((state) => [state.toggleModal]);

  const handleQuit = () => {
    setShow(false);
    toggleModal('quit');
  };

  const handleClickOutside = () => {
    setShow(false);
  };

  const handleSave = () => {
    setShow(false);
    toggleModal('save');
  };

  const ref = useOutsideClick(handleClickOutside);
  return (
    <Dropdown
      innerRef={ref}
      show={show}
      toggle={() => setShow(!show)}
      aria="Toggle Game Menu"
      label="Menu"
    >
      <TextButton lg onClick={handleSave}>
        Save Game
      </TextButton>
      <TextButton lg onClick={handleQuit}>
        Quit Game
      </TextButton>
    </Dropdown>
  );
};

export default GameNav;
