import styled from 'styled-components';
import { useGameStore } from '../../stores/gameStore';
import { colors, fonts } from '../../constants/variables';
import { transparentize } from 'polished';
import Button from '../Button';
import { useState } from 'react';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const FlexVotes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const Votes = styled.h2`
  font-size: 6rem;
  font-family: ${fonts.mono};
`;

const Input = styled.input`
  width: 3em;
  font-size: 2rem;
  padding: 0 0.25rem;
  font-family: ${fonts.mono};
  background-color: ${transparentize(0.5, colors.black)};
  border: solid 1px ${transparentize(0.5, colors.actionColor)};
  border-radius: 0.25rem;
  color: ${colors.white};
  outline: none;
  transition: border 0.3s, box-shadow 0.3s;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  &:hover {
    border: solid 1px ${colors.actionColor};
  }

  &:focus {
    border: solid 1px ${colors.actionColor};
    box-shadow: 0 0 0 3px ${transparentize(0.5, colors.actionColor)};
  }
`;

const Against = () => {
  const [newVotes, setNewVotes] = useState('');
  const [votes, voteAgainst] = useGameStore((state) => [
    state.votes,
    state.voteAgainst,
  ]);

  const handleVote = () => {
    voteAgainst(newVotes);
    setNewVotes('');
  };

  const handleFocus = (event) => event.target.select();
  return (
    <Div>
      <h1>Votes AGAINST</h1>
      <FlexVotes>
        <Button
          size="xs"
          square
          aria-label={`Decrease vote count`}
          outline
          disabled={votes.against === 0}
          onClick={() => voteAgainst(-1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 13H5v-2h14v2z" />
          </svg>
        </Button>
        <Votes>{votes.against}</Votes>
        <Button
          size="xs"
          square
          aria-label={`Increase vote count`}
          outline
          onClick={() => voteAgainst(1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
          </svg>
        </Button>
      </FlexVotes>
      <Flex>
        <Input
          value={newVotes}
          onChange={(e) => setNewVotes(e.target.value)}
          onFocus={handleFocus}
        />
        <Button
          size="xs"
          outline
          onClick={handleVote}
          disabled={newVotes === 0 || newVotes === ''}
        >
          {newVotes === '' && 'Insert Vote Count'}
          {newVotes > 0 && 'Add Votes'}
          {newVotes < 0 && 'Subtract Votes'}
        </Button>
        <small>Negative numbers will subtract votes</small>
      </Flex>
    </Div>
  );
};

export default Against;
