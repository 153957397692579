import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts } from '../constants/variables';
import { useGameStore } from '../stores/gameStore';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Div = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 50vw;
  grid-gap: 1rem;

  @media only screen and (max-width: ${breakpoints.sm}) {
    width: 75vw;
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.75rem;
  }
`;

const Background = styled.button`
  position: relative;
  width: 100%;
  padding-top: 66%;
  color: ${transparentize(0.5, colors.white)};
  ${(props) => {
    if (props.background !== '') {
      return css`
        background-image: url(${props.background});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      `;
    } else {
      return css`
        background-color: ${colors.space};
      `;
    }
  }}
  border:
    ${(props) => (props.active ? `solid 1px ${colors.actionColor}` : `none`)};
  cursor: pointer;
  transition: border 0.3s, box-shadow 0.3s;

  &:focus {
    box-shadow: 0 0 0 0.25rem ${transparentize(0.5, colors.actionColor)};
    outline: none;
  }
`;

const Span = styled.span`
  position: absolute;
  top: calc(50%);
  left: calc(50%);
  font-size: 1.5rem;
  font-family: ${fonts.fancy};
  transform: translateX(-50%) translateY(-50%);
  white-space: nowrap;

  @media only screen and (max-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

const Credit = styled.span`
  text-align: center;
  display: flex;
  width: 100%;
  margin-top: 1rem;
  font-style: italic;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  color: ${colors.actionColor};
`;

const OfficialGrid = ({ background, setBackground, setCredit }) => {
  const handleClick = (image, credit) => {
    setBackground(image);
    setCredit(credit);
  };

  return (
    <Grid>
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/ti_background_alt.webp`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background_alt.webp'
        }
        aria-label="Select background with space ships approaching a planet"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background_alt.webp',
            'Fantasy Flight Games'
          )
        }
      />
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/ti_background.webp`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background.webp'
        }
        aria-label="Select background with a massive space-based project being constructed against a sea of stars"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background.webp',
            'Fantasy Flight Games'
          )
        }
      />
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/ti_background_tert.webp`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background_tert.webp'
        }
        aria-label="Select background with numerous Hacan warriors rushing into battle"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background_tert.webp',
            'Fantasy Flight Games'
          )
        }
      />
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/ti_background_quad.webp`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background_quad.webp'
        }
        aria-label="Select background featuring the cover art for Twilight Imperium 4th Edition"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background_quad.webp',
            'Fantasy Flight Games'
          )
        }
      />
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/ti_background_turt.webp`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background_turt.webp'
        }
        aria-label="Select background with hundreds of Xxcha enganging in ground combat"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background_turt.webp',
            'Fantasy Flight Games'
          )
        }
      />
      <Background
        background=""
        active={background === ''}
        aria-label="Select a plain, dark blue-colored background"
        onClick={() => handleClick('', '')}
      >
        <Span>No Background</Span>
      </Background>
    </Grid>
  );
};

const UnofficialGrid = ({ background, setBackground, setCredit }) => {
  const handleClick = (image, credit) => {
    setBackground(image);
    setCredit(credit);
  };
  return (
    <Grid>
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/War%20were%20declared.jpg`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/War%20were%20declared.jpg'
        }
        aria-label="Select background with a larger number of space ships poised to attack, facing the viewer"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/War%20were%20declared.jpg',
            'Tom Dullemond (with AI Development Algorithm)'
          )
        }
      />
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/Infiltration.jpg`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/Infiltration.jpg'
        }
        aria-label="Select background with a space ship under siege, with an orange ring glowing around it"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/Infiltration.jpg',
            'Tom Dullemond (with AI Development Algorithm)'
          )
        }
      />
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/Planning%20sessions.jpg`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/Planning%20sessions.jpg'
        }
        aria-label="Select background with a dimly lit council chamber, with many councilors seated around it discussing politics"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/Planning%20sessions.jpg',
            'Tom Dullemond (with AI Development Algorithm)'
          )
        }
      />
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/Research%20Outposts.jpg`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/Research%20Outposts.jpg'
        }
        aria-label="Select background with a futuristic research station floating in the air above a desert canyon"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/Research%20Outposts.jpg',
            'Tom Dullemond (with AI Development Algorithm)'
          )
        }
      />
      <Background
        background={`https://ik.imagekit.io/ti4score/tr:w-450,h-280/Defense%20Systems.jpg`}
        active={
          background ===
          'https://ik.imagekit.io/ti4score/tr:bl-5/Defense%20Systems.jpg'
        }
        aria-label="Select background with massive planetary defense systems located on rolling, grassy fields"
        onClick={() =>
          handleClick(
            'https://ik.imagekit.io/ti4score/tr:bl-5/Defense%20Systems.jpg',
            'Tom Dullemond (with AI Development Algorithm)'
          )
        }
      />
      <Background
        background=""
        active={background === ''}
        aria-label="Select a plain, dark blue-colored background"
        onClick={() => handleClick('', '')}
      >
        <Span>No Background</Span>
      </Background>
    </Grid>
  );
};

const BackgroundPicker = ({ type }) => {
  const [credit, setCredit] = useState('');
  const [background, setBackground] = useGameStore((state) => [
    state.background,
    state.setBackground,
  ]);

  useEffect(() => {
    setCredit('');
  }, [type]);
  return (
    <Div>
      {type === 'official' && (
        <OfficialGrid
          background={background}
          setBackground={setBackground}
          setCredit={setCredit}
        />
      )}
      {type === 'unofficial' && (
        <UnofficialGrid
          background={background}
          setBackground={setBackground}
          setCredit={setCredit}
        />
      )}
      {credit !== '' && (
        <Credit>
          Image Credits: {credit}
          <br />
          <small>
            Have art you want to submit? Send it to{' '}
            <StyledLink to="mailto:ti4score@gmail.com">
              ti4score@gmail.com
            </StyledLink>
          </small>
        </Credit>
      )}
    </Div>
  );
};

export default BackgroundPicker;
