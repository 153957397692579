import fontColorContrast from 'font-color-contrast';
import { isEmpty } from 'lodash';
import { lighten, transparentize } from 'polished';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../constants/variables';
import { usePlayerStore } from '../stores/playerStore';
import { useOutsideClick } from '../utils/helpers';
import { PLAYER_COLORS } from '../constants/players';

const Color = styled.button`
  background-color: ${(props) =>
    props.color ? props.color : transparentize(0.75, colors.white)};
  width: 66px;
  height: 66px;
  border-radius: 0;
  border: solid 1px
    ${(props) =>
      props.color
        ? lighten(0.1, props.color)
        : transparentize(0.5, colors.white)};
  color: ${(props) =>
    props.color ? fontColorContrast(props.color) : colors.white};
  transition: background-color 0.3s, box-shadow 0.3s, border 0.3s, color 0.3s;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 0 0 3px
      ${(props) =>
        props.color
          ? transparentize(0.5, props.color)
          : transparentize(0.5, colors.white)};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Container = styled.div`
  position: relative;
`;

const ColorSelect = styled.div`
  position: absolute;
  top: 0;
  right: 66px;
  background-color: ${colors.black};
  border: solid 1px ${lighten(0.3, colors.black)};
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0;
  gap: 1rem;
  box-shadow: 0 0.5rem 2rem ${transparentize(0.8, colors.white)};
`;

const ColorList = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5rem;
`;

const Li = styled.li`
  list-style-type: none;
`;

const ColorPick = styled.button`
  height: 35px;
  width: 35px;
  border-radius: 0.25rem;
  box-sizing: border-box;
  ${(props) => {
    return css`
      background-color: ${props.color};
      border: solid 1px
        ${props.active ? lighten(0.5, props.color) : lighten(0.2, props.color)};
      box-shadow: 0 0 0 3px
        ${props.active
          ? lighten(0.2, transparentize(0.5, props.color))
          : 'transparent'};

      &:hover {
        cursor: pointer;
        background-color: ${lighten(0.1, props.color)};
      }
    `;
  }}
`;

const ColorButton = ({ id, color }) => {
  const [showColors, setShow] = useState(false);
  const [player, setColor] = usePlayerStore((state) => [
    state.players[id],
    state.setColor,
  ]);
  const playerColor = player.color;
  let preferredColors = [];

  if (player.faction) {
    preferredColors = player.faction.preferred_colors;
  }

  const chooseColor = (color) => {
    setColor(id, color);
    setShow(!showColors);
  };

  const handleClickOutside = () => {
    setShow(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <Container ref={ref}>
      {showColors && (
        <ColorSelect>
          <div>
            <h2>Faction Colors</h2>
            <ColorList>
              {preferredColors.map((color, index) => {
                return (
                  <Li key={`color_${index}`}>
                    <ColorPick
                      color={color}
                      active={playerColor === color}
                      title={color}
                      onClick={() => chooseColor(color)}
                    />
                  </Li>
                );
              })}
            </ColorList>
          </div>
          <div>
            <h2>All Colors</h2>
            <ColorList>
              {PLAYER_COLORS.map((color, index) => {
                return (
                  <Li key={`color_${index}`}>
                    <ColorPick
                      color={color}
                      active={playerColor === color}
                      title={color !== '#222222' ? color : 'black'}
                      onClick={() => chooseColor(color)}
                    />
                  </Li>
                );
              })}
            </ColorList>
          </div>
        </ColorSelect>
      )}
      <Color
        id={id}
        color={playerColor}
        onClick={() => setShow(!showColors)}
        disabled={isEmpty(player.faction)}
      >
        Player Color
      </Color>
    </Container>
  );
};

export default ColorButton;
