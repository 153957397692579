import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

import { useGameStore } from '../../stores/gameStore/gameStore';
import { deleteSaveFromLocal } from '../../utils/helpers';

const P = styled.p`
  font-size: 1.25rem;
`;

const ModalDelete = () => {
  const [toggleModal, toggleAlert, modalKey, setModalKey] = useGameStore(
    (state) => [
      state.toggleModal,
      state.toggleAlert,
      state.modalKey,
      state.setModalKey,
      state.fireDeleteSaveEvent,
    ]
  );

  const handleDelete = () => {
    toggleModal();
    deleteSaveFromLocal(modalKey);
    setModalKey(undefined);
    toggleAlert({
      type: 'success',
      text: 'Game was deleted successfully',
      show: true,
    });
  };

  return (
    <>
      <ModalBody>
        <P>Would you like to delete this game?</P>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleDelete} size="sm">
          Delete Game
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalDelete;
