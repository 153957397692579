import React from 'react';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { usePlayerStore } from '../../stores/playerStore';
import Button from '../Button';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const ModalCustodians = () => {
  const [scorePoint, players] = usePlayerStore((state) => [
    state.scorePoint,
    state.players,
  ]);
  const [playCard, currentRound, custodianId] = useGameStore((state) => [
    state.playCard,
    state.currentRound,
    state.custodianId,
  ]);
  const claimPlayer = players.filter((player) => player.id === custodianId)[0];

  const handleClaim = () => {
    playCard('custodiansClaimed', true);
    scorePoint(claimPlayer.id, currentRound, 'custodians', true);
  };
  return (
    <>
      <ModalBody>
        <p>
          Did <strong>{claimPlayer.faction.name}</strong> claim the{' '}
          <strong>CUSTODIANS TOKEN</strong>?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleClaim} size="sm">
          Claim Custodians
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalCustodians;
