import { devtools } from 'zustand/middleware';
import create from 'zustand';
import { DISCORDANT_FACTIONS, FACTIONS } from '../../constants/factions';
import { player } from '../../constants/players';
import { initialState } from './constants';
import { getInitialFactionsHash } from '../../utils/helpers';
import {
  getNextAvailableColor,
  isPreferredColorTaken,
  pickPlayerColor,
} from './utils';

const basicPlayer = player;

export const usePlayerStore = create(
  devtools(
    (set, get) => ({
      ...initialState,

      initPlayers: (arg) => {
        let tempPlayers = [];
        for (let i = 0; i < arg; i++) {
          tempPlayers.push({ ...structuredClone(basicPlayer), id: i });
        }
        set({
          players: tempPlayers,
          playerCount: arg,
          chosenFactions: getInitialFactionsHash(),
        });
      },

      setScoreLimit: (arg) => {
        let newExpected = [];
        if (arg >= 14) {
          newExpected = [1, 2, 3, 4, 5, 6, 7];
        } else if (arg <= 10) {
          newExpected = [1, 2, 3, 4, 5];
        } else if (arg === 12) {
          newExpected = [1, 2, 3, 4, 5, 6];
        }
        set({
          scoreLimit: arg,
          expectedRounds: newExpected,
        });
      },

      setName: (playerId, arg) => {
        const newPlayers = structuredClone(get().players);
        newPlayers[playerId].name = arg;

        set({ players: newPlayers });
      },

      setColor: (playerId, arg) => {
        const newPlayers = structuredClone(get().players);
        const chosenColors = structuredClone(get().chosenColors);
        // remove current color from chosenColors
        if (newPlayers[playerId].color)
          chosenColors[newPlayers[playerId].color] = false;
        newPlayers[playerId].color = arg;
        // set new color on choseColors
        chosenColors[arg] = true;
        set({ players: newPlayers, chosenColors: chosenColors });
      },

      setFaction: (playerId, arg) => {
        const players = structuredClone(get().players);
        const player = structuredClone(players[playerId]);
        const factions = [...FACTIONS, ...DISCORDANT_FACTIONS];

        // get chosen faction based on id passed in arg
        const faction = factions.filter(
          (faction) => faction.id === parseInt(arg)
        )[0];

        // set that chosen faction to true in our faction hash indicating it has been chosen
        const newChosenFactionsHash = structuredClone(get().chosenFactions);
        newChosenFactionsHash[arg] = true;
        // remove previous faction from list of chosen by flipping value to false
        if (player.faction) newChosenFactionsHash[player.faction.id] = false;
        // same for colors
        const chosenColors = structuredClone(get().chosenColors);

        if (player.color) chosenColors[player.color] = false;

        // override color if it's already taken
        let colorOverride;
        if (
          isPreferredColorTaken(get().chosenColors, faction.preferred_colors[0])
        )
          colorOverride = getNextAvailableColor(
            get().chosenColors,
            faction.preferred_colors
          );

        player.faction = faction !== undefined ? faction : {};
        player.color = pickPlayerColor(
          faction,
          player.faction.preferred_colors[0],
          colorOverride
        );

        chosenColors[player.color] = true;
        // replace our modified player in the list of players
        players[playerId] = player;
        set({
          players: players,
          chosenFactions: newChosenFactionsHash,
          chosenColors: chosenColors,
        });
      },

      setExpectedRounds: (arg) => {
        const rounds = structuredClone(get().expectedRounds);
        rounds.push(arg);
        set({
          expectedRounds: rounds,
        });
      },

      scorePoint: (
        playerId,
        currentRound,
        pointType,
        isAdd = true,
        objective
      ) => {
        // in case there's a winner
        let newWinner = structuredClone(get().winner);
        // add or subtract point?
        let diff = 0;
        if (isAdd) {
          diff = pointType === 'stage_2' ? 2 : 1;
        } else {
          diff = pointType === 'stage_2' ? -2 : -1;
        }
        // don't let the player score more than scoreLimit
        if (
          (get().players[playerId].totalPoints >= get().scoreLimit ||
            get().players[playerId].points[currentRound - 1] >=
              get().scoreLimit) &&
          isAdd
        )
          return;

        const newPlayers = structuredClone(get().players);
        newPlayers[playerId].point_by_type[pointType] +=
          pointType === 'stage_2' ? diff / 2 : diff;
        // basically, don't subtract points if there's no... point
        if (newPlayers[playerId].totalPoints !== 0 || diff > 0) {
          newPlayers[playerId].points[currentRound - 1].y =
            get().players[playerId].totalPoints + diff;
          newPlayers[playerId].totalPoints += diff;
        }
        // set a new winner if the score is high enough
        if (newPlayers[playerId].totalPoints >= get().scoreLimit) {
          newWinner = newPlayers[playerId];
        }
        // if the point scored was an objective, we need to mark what it was
        if (objective !== undefined) {
          if (isAdd) {
            newPlayers[playerId].scored_objectives[pointType].push(objective);
          } else {
            const delObj =
              newPlayers[playerId].scored_objectives[pointType].indexOf(
                objective
              );
            newPlayers[playerId].scored_objectives[pointType].splice(delObj, 1);
          }
        }
        set({
          players: newPlayers,
          winner: newWinner,
        });
      },

      playerUpdateClassified: (playerId, arg) => {
        const newPlayers = structuredClone(get().players);
        newPlayers[playerId].scored_objectives.classified[0] = arg;
        set({
          players: newPlayers,
        });
      },

      playerClassified: (playerId) => {
        const newPlayers = structuredClone(get().players);
        newPlayers[playerId].point_by_type.secret -= 1;
        newPlayers[playerId].point_by_type.classified += 1;
        newPlayers[playerId].scored_objectives.classified.push('');
        set({
          players: newPlayers,
        });
      },

      playersNextRound: (currentRound) => {
        const newPlayers = structuredClone(get().players);
        newPlayers.forEach((player) => {
          player.points[currentRound].y = player.totalPoints;
        });
        set({
          players: newPlayers,
        });
      },

      playerObsidian: (playerId) => {
        const newPlayers = structuredClone(get().players);
        newPlayers[playerId].hasObsidian = true;
        set({
          players: newPlayers,
        });
      },

      playerAddVotes: (playerId) => {
        const newPlayers = structuredClone(get().players);
        newPlayers[playerId].votes += 1;
        set({
          players: newPlayers,
        });
      },

      playerRemoveVotes: (playerId) => {
        const newPlayers = structuredClone(get().players);
        newPlayers[playerId].votes -= 1;
        set({
          players: newPlayers,
        });
      },

      playerSetVotes: (playerId, newVotes) => {
        const newPlayers = structuredClone(get().players);
        newPlayers[playerId].votes = newVotes;
        set({
          players: newPlayers,
        });
      },

      reset: () => {
        set(initialState);
      },
    }),
    {
      name: 'player-store',
    }
  )
);
