import React, { useState } from 'react';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { usePlayerStore } from '../../stores/playerStore';
import Button from '../Button';
import FactionRadios from '../FactionRadios';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const ModalShard = () => {
  const [scorePoint, players] = usePlayerStore((state) => [
    state.scorePoint,
    state.players,
  ]);
  const [playCard, currentRound] = useGameStore((state) => [
    state.playCard,
    state.currentRound,
  ]);
  const list = players.filter((player) => {
    return player.point_by_type.shard === 0;
  });
  const [selectedFaction, setSelection] = useState(list[0].id);

  const handleShard = () => {
    const shardPlayer = players.filter((player) => {
      return player.point_by_type.shard > 0;
    });
    if (shardPlayer.length > 0) {
      scorePoint(shardPlayer[0].id, currentRound, 'shard', false);
    }
    scorePoint(selectedFaction, currentRound, 'shard', true);
  };
  return (
    <>
      <ModalBody>
        <p>
          Which faction has <strong>GAINED</strong> the <strong>SHARD</strong>?
        </p>
        <FactionRadios
          selected={selectedFaction}
          setSelection={setSelection}
          list={list}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            playCard('shardOfTheThrone', true);
            handleShard();
          }}
          size="sm"
        >
          Gain Shard
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalShard;
