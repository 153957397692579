import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import styled from 'styled-components';
import Main from './components/Main';
import { colors } from './constants/variables';
import Error from './routes/Error';
import FAQs from './routes/FAQs';
import Home from './routes/Home';
import HowItWorks from './routes/HowItWorks';
import LoadGame from './routes/Load';
import New from './routes/New';
import Play from './routes/Play';
import { useGameStore } from './stores/gameStore';
import { GlobalStyle } from './styles/GlobalStyle';
import BackgroundView from './routes/BackgroundView';

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${colors.space};
`;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    errorElement: <Error />,
    children: [
      {
        errorElement: <Error />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: 'new-game',
            element: <New />,
          },
          {
            path: 'select-background',
            element: <BackgroundView />,
          },
          {
            path: 'load-game',
            element: <LoadGame />,
          },
          {
            path: 'how-it-works',
            element: <HowItWorks />,
          },
          {
            path: 'frequently-asked-questions',
            element: <FAQs />,
          },
          {
            path: '/play',
            element: <Play />,
          },
        ],
      },
    ],
  },
]);
function App() {
  const background = useGameStore((state) => state.background);
  return (
    <>
      <GlobalStyle />
      <RouterProvider router={router} />
      <Background background={background} />
    </>
  );
}

export default App;
