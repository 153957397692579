import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import { usePlayerStore } from '../stores/playerStore';
import { transparentize } from 'polished';
import { colors } from '../constants/variables';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Ul = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  margin: 0;
  padding: 0;
`;

const LeftButton = styled(Button)`
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
  margin: 0;
`;

const RightButton = styled(Button)`
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: none;
  margin: 0;
`;

const Score = styled.h2`
  font-size: 2rem;
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Input = styled.input`
  width: 2em;
  font-size: 2rem;
  padding: 0 0.25rem;
  background-color: ${transparentize(0.5, colors.black)};
  border: solid 1px ${transparentize(0.5, colors.actionColor)};
  border-radius: 0.25rem;
  color: ${colors.white};
  outline: none;
  transition: border 0.3s, box-shadow 0.3s;

  &:hover {
    border: solid 1px ${colors.actionColor};
  }

  &:focus {
    border: solid 1px ${colors.actionColor};
    box-shadow: 0 0 0 3px ${transparentize(0.5, colors.actionColor)};
  }
`;

const PointSetter = () => {
  const setScoreLimit = usePlayerStore((state) => state.setScoreLimit);
  const scoreLimit = usePlayerStore((state) => state.scoreLimit);
  const min = 8;
  const max = 20;

  const handleInput = (e) => {
    const { value, maxLength } = e.target;
    let score = value.slice(0, maxLength);
    if (score > max) {
      score = max;
    } else if (score === '') {
      score = min;
    }
    setScoreLimit(parseInt(score));
  };
  return (
    <Section aria-labelledby="victory">
      <h1 id="victory">How many points for victory?</h1>
      <Ul role="toolbar">
        <Li>
          <LeftButton
            size="sm"
            outline
            active={scoreLimit === 10}
            onClick={() => setScoreLimit(10)}
          >
            10
          </LeftButton>
        </Li>
        <Li>
          <RightButton
            size="sm"
            outline
            active={scoreLimit === 14}
            onClick={() => setScoreLimit(14)}
          >
            14
          </RightButton>
        </Li>
      </Ul>
      <Score>
        <Input
          type="number"
          value={scoreLimit}
          onChange={(e) => handleInput(e)}
          min={min}
          max={max}
          maxLength={2}
        />
        Points
      </Score>
    </Section>
  );
};

export default PointSetter;
