import styled from 'styled-components';
import { colors } from '../constants/variables';

const Flex = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const Ball = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${(props) => (props.active ? props.color : colors.midGray)};
`;

const ScoreBalls = ({ total, scored = total, color = colors.actionColor }) => {
  if (!total) return <div></div>;
  return (
    <Flex>
      {[...Array(total)].map((value, index) => {
        return <Ball key={index} active={index + 1 <= scored} color={color} />;
      })}
    </Flex>
  );
};

export default ScoreBalls;
