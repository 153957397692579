import styled from 'styled-components';
import { fonts } from '../constants/variables';

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.span`
  display: block;
  font-size: 1.25rem;
  font-family: ${fonts.mono};
`;

const Unscored = styled.span`
  opacity: 0.25;
`;

const ObjectiveBlock = ({ label, scored }) => {
  return (
    <Box>
      <Heading>{label}</Heading>
      {scored.length > 0 || scored[0] === '' ? (
        scored.map((obj) => {
          return (
            <span key={obj}>{obj !== '' ? obj : 'Awaiting Selection...'}</span>
          );
        })
      ) : (
        <Unscored>
          <em>None</em>
        </Unscored>
      )}
    </Box>
  );
};

export default ObjectiveBlock;
