import React from 'react';
import styled from 'styled-components';
import { fonts } from '../constants/variables';
import { useGameStore } from '../stores/gameStore';
import { usePlayerStore } from '../stores/playerStore';
import Button from './Button';
import PointTrackers from './PointTrackers';

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Span = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.25rem;
  font-family: ${fonts.mono};
`;

const ScoreBlock = ({ label, type, max, scored, id, disabled }) => {
  const [scorePoint] = usePlayerStore((state) => [state.scorePoint]);
  const currentRound = useGameStore((state) => state.currentRound);
  return (
    <Flex>
      <Span>
        {label}
        <PointTrackers total={max} scored={scored} />
      </Span>
      <Button
        size="xs"
        square
        aria-label={`Lose a ${label} Point`}
        outline
        disabled={scored === 0}
        onClick={() => scorePoint(id, currentRound, type, false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 13H5v-2h14v2z" />
        </svg>
      </Button>
      <Span>{scored}</Span>
      <Button
        size="xs"
        square
        aria-label={`Gain a ${label} Point`}
        outline
        disabled={disabled !== undefined ? disabled : scored >= max}
        onClick={() => scorePoint(id, currentRound, type, true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        </svg>
      </Button>
    </Flex>
  );
};

export default ScoreBlock;
