import { lighten } from 'polished';
import styled from 'styled-components';
import { colors } from '../../constants/variables';

const Indicator = styled.span`
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: ${(props) =>
    `solid 1px ${lighten(0.2, props.active ? props.color : colors.black)}`};
  background-color: ${(props) => (props.active ? props.color : colors.black)};
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: border 0.3s, background-color 0.3s, opacity 0.3s;
`;

const Img = styled.img`
  width: 1rem;
`;

const ScoreIndicator = ({
  id,
  name,
  icon,
  color,
  count,
  selected,
  scored,
  type,
}) => {
  const active = scored.includes(selected);
  if (active) {
    return (
      <Indicator
        color={color}
        count={count}
        active={active}
        aria-label={`${active ? 'Unscored' : 'Scored'} objective for ${name}`}
      >
        <Img src={icon} />
      </Indicator>
    );
  } else {
    return null;
  }
};

export default ScoreIndicator;
