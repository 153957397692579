import styled from 'styled-components';
import Button from '../Button';
import { lighten, transparentize } from 'polished';
import { breakpoints, colors, fonts } from '../../constants/variables';
import { usePlayerStore } from '../../stores/playerStore';

const Div = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  border: solid 1px
    ${(props) =>
      props.color === 'black' ? lighten(0.1, props.color) : props.color};
  background-color: ${(props) => transparentize(0.95, props.color)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${breakpoints.md}) {
    padding: 0.25rem 0.5rem;
    gap: 0.25rem;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const H1 = styled.h1`
  margin: 0;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  @media only screen and (max-width: ${breakpoints.lg}) {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    font-size: 0.85rem;
  }
`;

const Span = styled.span`
  font-family: ${fonts.mono};
  font-size: 89%;
  font-weight: 600;
`;

const H2 = styled.h2`
  font-family: ${fonts.fancy};

  @media only screen and (max-width: ${breakpoints.md}) {
    font-size: 0.75rem;
  }
`;

const Votes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Input = styled.input`
  width: 2em;
  font-size: 2rem;
  padding: 0 0.25rem;
  font-family: ${fonts.mono};
  background-color: ${transparentize(0.5, colors.black)};
  border: solid 1px ${transparentize(0.5, colors.actionColor)};
  border-radius: 0.25rem;
  color: ${colors.white};
  outline: none;
  transition: border 0.3s, box-shadow 0.3s;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  &:hover {
    border: solid 1px ${colors.actionColor};
  }

  &:focus {
    border: solid 1px ${colors.actionColor};
    box-shadow: 0 0 0 3px ${transparentize(0.5, colors.actionColor)};
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 1.5rem;
  }
`;

const VoterBlock = ({ player }) => {
  const [playerAddVotes, playerRemoveVotes, playerSetVotes] = usePlayerStore(
    (state) => [
      state.playerAddVotes,
      state.playerRemoveVotes,
      state.playerSetVotes,
    ]
  );

  const handleSetVotes = (votes) => {
    if (votes !== '') {
      playerSetVotes(player.id, Number(votes));
    }
  };

  const handleFocus = (event) => event.target.select();
  return (
    <Div color={player.color}>
      <Text>
        <H1>
          {player.faction.name}
          <Span aria-label="Total points">{player.totalPoints}</Span>
        </H1>
        {player.name && <H2>{player.name}</H2>}
      </Text>
      <Votes>
        <Button
          size="xs"
          square
          aria-label={`Decrease vote count`}
          outline
          disabled={player.votes === 0}
          onClick={() => playerRemoveVotes(player.id)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 13H5v-2h14v2z" />
          </svg>
        </Button>
        <Input
          value={player.votes || 0}
          onFocus={handleFocus}
          type="number"
          onChange={(e) => handleSetVotes(e.target.value)}
        />
        <Button
          size="xs"
          square
          aria-label={`Increase vote count`}
          outline
          onClick={() => playerAddVotes(player.id)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
          </svg>
        </Button>
      </Votes>
      <small style={{ textAlign: 'center' }}>Available Votes</small>
    </Div>
  );
};

export default VoterBlock;
