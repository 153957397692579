import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '../constants/variables';

const Button = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  color: ${transparentize(0.25, colors.actionColor)};
  white-space: nowrap;
  text-decoration: ${(props) => (props.repealed ? 'line-through' : 'none')};
  transition: color 0.3s;
  padding: 0.25rem 0;
  font-size: ${(props) => (props.lg ? '1rem' : '0.85rem')};

  &:hover:not([disabled]) {
    cursor: pointer;
    color: ${colors.actionColor};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: ${(props) => (props.active ? 1 : 0.5)};
  }
`;

const TextButton = ({ children, lg, disabled = false, ...props }) => {
  return (
    <Button {...props} lg={lg} disabled={disabled}>
      {children}
    </Button>
  );
};

export default TextButton;
