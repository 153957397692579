const RelicIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 130.5 145"
    >
      <g>
        <path
          d="M59.1,101.7v42.8l-27.8-27.8c2.9-2.7,4.8-6.5,4.8-10.8c0-8.1-6.5-14.6-14.6-14.6c-4.3,0-8.1,1.8-10.8,4.8
		L0,85.4V44.4L31,0.5v73.2L59.1,101.7z"
        />
        <polygon points="85,36.1 62.2,58.6 62.2,87.2 43,68.3 43,38.6 65.3,16.7" />
        <polygon points="87.5,42.3 87.5,68.3 68.3,87.2 68.3,61.2" />
        <g>
          <path
            d="M71.4,101.7v42.8l27.8-27.8c-2.9-2.7-4.8-6.5-4.8-10.8c0-8.1,6.5-14.6,14.6-14.6c4.3,0,8.1,1.8,10.8,4.8
			l10.7-10.7V44.4l-31-43.9v73.2L71.4,101.7z"
          />
        </g>
      </g>
    </svg>
  );
};

export default RelicIcon;
