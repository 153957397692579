export const isPreferredColorTaken = (chosenColors, color) =>
  chosenColors[color];

export const getNextAvailableColor = (chosenColors, preferredColors) => {
  // return any other preferred colors
  let colorOverride;
  if (preferredColors.length > 1) {
    preferredColors.forEach((color) => {
      if (!isPreferredColorTaken(chosenColors, color) && !colorOverride) {
        colorOverride = color;
      }
    });
  }

  // return any color not taken
  for (const [color, isColorTaken] of Object.entries(chosenColors)) {
    if (!isColorTaken && !colorOverride) {
      colorOverride = color;
    }
  }

  return colorOverride;
};

export const pickPlayerColor = (faction, preferredColor, colorOverride) => {
  if (colorOverride) return colorOverride;
  return faction !== undefined && faction !== null ? preferredColor : '';
};
