import styled from 'styled-components';
import { PUBLIC, SECRETS } from '../../constants/objectives';
import { breakpoints } from '../../constants/variables';
import { useGameStore } from '../../stores/gameStore';
import ObjectiveBlock from './ObjectiveBlock';
import _ from 'lodash';

const Section = styled.section`
  flex: 1;
  display: flex;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Stages = styled.div`
  display: grid;
  grid-template-rows: repeat(${(props) => (props.leak ? 3 : 2)}, 1fr);
  grid-row-gap: 1rem;
  width: 100%;

  @media only screen and (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const ObjectiveContainer = () => {
  const [
    incentiveType,
    availStage1,
    availStage2,
    maxStage1,
    maxStage2,
    maxClassified,
    classifiedDocument,
    pok,
  ] = useGameStore((state) => [
    state.incentiveType,
    state.availStage1,
    state.availStage2,
    state.maxStage1,
    state.maxStage2,
    state.maxClassified,
    state.classifiedDocument,
    state.pok,
  ]);

  let stage_1 = _.sortBy(
    PUBLIC.filter((obj) => obj.type === 'stage_1'),
    ['name']
  );
  // further reduce objective lists if we're in base game
  let stage_2 = _.sortBy(
    PUBLIC.filter((obj) => obj.type === 'stage_2'),
    ['name']
  );
  let secrets = _.sortBy(SECRETS, ['name']);
  if (!pok) {
    stage_1 = stage_1.filter((obj) => obj.expansion === false);
    stage_2 = stage_2.filter((obj) => obj.expansion === false);
    secrets = secrets.filter((obj) => obj.expansion === false);
  }
  return (
    <Section aria-label="Scorable Objectives">
      <Stages leak={classifiedDocument}>
        <ObjectiveBlock
          options={stage_1}
          type="stage_1"
          incentiveType={incentiveType}
          maxAllowed={availStage1}
          legend="Stage 1"
          count={maxStage1}
        />
        <ObjectiveBlock
          options={stage_2}
          type="stage_2"
          incentiveType={incentiveType}
          maxAllowed={availStage2}
          legend="Stage 2"
          count={maxStage2}
        />
        {classifiedDocument && (
          <ObjectiveBlock
            options={secrets}
            type="classified"
            maxAllowed={maxClassified}
            legend="Classified Document"
            count={1}
          />
        )}
      </Stages>
    </Section>
  );
};

export default ObjectiveContainer;
