export const colors = {
  white: '#ffffff',
  border: '#cfcfcf',
  midGray: '#666666',
  black: '#010101',
  stage_1: '#F0AD1A',
  stage_2: '#058ECC',
  secret: '#E01541',
  agenda: '#128EDD',
  law: '#D1AE6A',
  directive: '#F8FE06',
  custodians: '#D69E3A',
  imperial: '#75328B',
  cardBack: '#00070A',
  actionColor: '#F46221',
  success: '#19850F',
  error: '#D0140C',
  space: '#12172A',
};

export const breakpoints = {
  xxl: '120em',
  xl: '100em',
  lg: '85.375em',
  md: '64em',
  sm: '48em',
  xs: '32em',
  xxs: '25.6875em',
};

export const fonts = {
  standard: 'proxima-nova, sans-serif',
  fancy: 'handel-gothic, sans-serif',
  title: 'ambroise-firmin-std, serif',
  mono: 'fira-mono, monospace',
};
