import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/variables';
import { useGameStore } from '../../stores/gameStore/gameStore';
import Button from '../Button';

const StyledSection = styled.section`
  padding: 1rem;
  border-top: solid 1px ${transparentize(0.9, colors.white)};
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const ModalFooter = ({ hideCancel = false, ...props }) => {
  const toggleModal = useGameStore((state) => state.toggleModal);
  const { children } = props;
  return (
    <StyledSection aria-label="Modal Actions">
      {!hideCancel && (
        <Button onClick={() => toggleModal()} size="sm" outline>
          Cancel
        </Button>
      )}
      {children}
    </StyledSection>
  );
};

export default ModalFooter;
