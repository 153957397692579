import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '../constants/variables';

const Div = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2rem;
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:focus {
    outline: none;

    + span:before {
      box-shadow: 0 0 0 3px ${transparentize(0.5, colors.white)};
    }
  }
`;

const Span = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.toggled ? colors.actionColor : transparentize(0.75, colors.white)};
  transition: 0.3s;
  border-radius: 1rem;

  &:before {
    position: absolute;
    content: '';
    height: 1.5rem;
    width: 1.5rem;
    left: 0.25rem;
    bottom: 0.25rem;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
    transform: ${(props) => (props.toggled ? 'translateX(2rem)' : null)};
  }
`;

const Text = styled.span`
  width: max-content;
  cursor: pointer;
`;

const Toggle = ({ label, toggled, onClick }) => {
  const callback = () => {
    onClick(!toggled);
  };
  return (
    <Div>
      <Text onClick={callback}>{label}</Text>
      <Label>
        <Checkbox type="checkbox" checked={toggled} onChange={callback} />
        <Span toggled={toggled} />
      </Label>
    </Div>
  );
};

export default Toggle;
