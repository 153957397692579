import styled from 'styled-components';
import LinkButton from '../components/LinkButton';
import Load from '../components/Load';
import { breakpoints, fonts } from '../constants/variables';
import { titleText } from '../utils/helpers';

const GoBack = styled(LinkButton)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const H1 = styled.h1`
  font-family: ${fonts.title};
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;

  span {
    font-family: ${fonts.fancy};
    font-weight: 300;
    text-transform: none;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 1.25rem;
  }
`;

const LoadGame = () => {
  titleText('Load a Game - ti4score');
  return (
    <>
      <H1>
        <span>Load a Game</span>
      </H1>
      <GoBack outline size="sm" square to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
        </svg>
      </GoBack>
      <Load />
    </>
  );
};

export default LoadGame;
