import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useGameStore } from '../stores/gameStore';
import { usePlayerStore } from '../stores/playerStore';
import { getGameStateFromLocal, getSavedGameKeys } from '../utils/helpers';
import Button from './Button';
import List from './List';

const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;

  h1 {
    margin: 0;
  }
`;

const Load = () => {
  let navigate = useNavigate();
  const [selected, setSelected] = useState(undefined);
  const [resetGameState] = useGameStore((state) => [state.reset]);
  const [resetPlayerState] = usePlayerStore((state) => [state.reset]);
  const savedGameKeys = getSavedGameKeys();

  const handleLoadGame = (id) => {
    const state = getGameStateFromLocal(id);
    resetGameState();
    resetPlayerState();
    usePlayerStore.setState(state.playerState);
    useGameStore.setState(state.gameState);
    navigate('/play', { replace: true });
  };

  return (
    <Section aria-labelledby="load">
      <List
        list={savedGameKeys ? savedGameKeys : undefined}
        selected={selected}
        onClick={setSelected}
      />
      <Button
        size="sm"
        onClick={() => handleLoadGame(selected)}
        disabled={!selected}
      >
        {selected ? 'Load Selected Game' : 'Choose a Game'}
      </Button>
    </Section>
  );
};

export default Load;
