import styled from 'styled-components';
import Radio from './Radio';
import { useGameStore } from '../stores/gameStore';
import { usePlayerStore } from '../stores/playerStore';

const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Legend = styled.legend`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

const GameToggle = () => {
  const [playerCount, initPlayers] = usePlayerStore((state) => [
    state.playerCount,
    state.initPlayers,
  ]);
  const [pok, toggleGameVersion] = useGameStore((state) => [
    state.pok,
    state.toggleGameVersion,
  ]);
  const handleToggle = (gameType) => {
    initPlayers(playerCount);
    toggleGameVersion(gameType);
  };

  return (
    <Section>
      <h1>Which game version are you playing?</h1>
      <Fieldset>
        <Legend>Select a game version</Legend>
        <Radio
          id="base"
          label="Base Game"
          checked={!pok}
          name="game_type"
          onChange={() => handleToggle('base')}
          disabled={false}
        />
        <Radio
          id="pok"
          label="Prophecy of Kings (Includes all codices)"
          checked={pok}
          name="game_type"
          onChange={() => handleToggle('pok')}
          disabled={false}
        />
      </Fieldset>
    </Section>
  );
};

export default GameToggle;
