import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints, colors, fonts } from '../constants/variables';
import LinkButton from '../components/LinkButton';
import { titleText } from '../utils/helpers';

const H1 = styled.h1`
  font-family: ${fonts.title};
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;

  span {
    font-family: ${fonts.fancy};
    font-weight: 300;
    text-transform: none;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 1.25rem;
  }
`;

const Flex = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
`;

const Nav = styled.nav`
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
`;

const StyledLink = styled(Link)`
  color: ${colors.actionColor};
`;

const StyledLinkButton = styled(LinkButton)`
  width: fit-content;
`;

const H2 = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 300;
  font-family: ${fonts.fancy};

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 1.25rem;
  }
`;

const SupportBlock = styled.aside`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    max-width: 23em;
    text-align: center;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    padding: 0 0.5rem;
  }
`;

const Home = () => {
  titleText('Twilight Imperium Score Tracker - ti4score');
  return (
    <>
      <H1>
        Twilight Imperium: <span>Score Tracker</span>
      </H1>
      <Flex>
        <H2>Choose an option to get started</H2>
        <StyledLinkButton size="lg" to="/new-game">
          New Game
        </StyledLinkButton>
        <StyledLinkButton outline size="sm" to="/load-game">
          Load Game
        </StyledLinkButton>
      </Flex>
      <Flex>
        <H2>Want a different background?</H2>
        <StyledLinkButton size="sm" outline to="/select-background">
          Change Background
        </StyledLinkButton>
      </Flex>
      <Flex>
        <H2>Support... for the Throne</H2>
        <SupportBlock>
          <span>
            I do this for fun, but you can send me your extra trade goods if you
            want.
            <br />
            <StyledLink
              rel="noreferer noopener"
              target="_blank"
              to="https://buymeacoffee.com/ironnmetal"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Offer trade goods
              <img src="/open_in_new.svg" alt="" />
            </StyledLink>
          </span>
        </SupportBlock>
      </Flex>
      <Nav>
        <StyledLink to="/how-it-works">How It Works</StyledLink>
        <StyledLink to="/frequently-asked-questions">FAQs</StyledLink>
      </Nav>
    </>
  );
};

export default Home;
