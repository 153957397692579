import styled from 'styled-components';
import LinkButton from '../components/LinkButton';
import { breakpoints, fonts } from '../constants/variables';
import { titleText } from '../utils/helpers';

const GoBack = styled(LinkButton)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const H1 = styled.h1`
  font-family: ${fonts.title};
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;

  span {
    font-family: ${fonts.fancy};
    font-weight: 300;
    text-transform: none;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 1.25rem;
  }
`;

const Div = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-width: 50em;
`;

const Row = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @media only screen and (max-width: ${breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

const H2 = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  white-space: normal;

  &:not(:first-of-type) {
    margin-top: 3rem;
  }
`;

const P = styled.p`
  line-height: 1.5;
  max-width: 75ch;
`;

const Img = styled.img`
  max-width: 100%;
`;

const HowItWorks = () => {
  titleText('How It Works - ti4score');
  return (
    <>
      <H1>
        <span>How It Works</span>
      </H1>
      <GoBack outline size="sm" square to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
        </svg>
      </GoBack>
      <h1>Getting Started</h1>
      <Div>
        <Row aria-labelledby="image">
          <div>
            <H2 id="image">Choose a background image</H2>
            <P>
              When the app first loads, you will see a series of images
              presented in a grid pattern. Click on an image to set a new
              background. The selected image will then be set as the background
              image for a new game. They are blurred slightly to make the
              foreground content readable.
            </P>
            <P>
              If you find the background images too distracting, the last option
              is a solid color, which greatly enhances readability.
            </P>
            <P>
              This selection is remembered from game to game, so you can have
              different background images for different games.
            </P>
            <H2>Starting a new game</H2>
            <P>
              After picking a background image, you will then choose to either
              start a new game or load a previously saved game.
            </P>
          </div>
          <Img
            src="https://ik.imagekit.io/ti4score/background-selection.png"
            alt="A series of possible background images, presented in a grid pattern. The first image is highlighted"
          />
        </Row>
        <Row aria-labelledby="select">
          <div>
            <H2 id="select">Select the number of people playing</H2>
            <P>
              When starting a new game, you will first choose the number of
              people playing. The slider supports 2–8 players and defaults to 6.
            </P>
          </div>
          <Img
            src="https://ik.imagekit.io/ti4score/player-count-slider.png"
            alt="Slider showing the number of people playing a game, currently set to six"
          />
        </Row>
        <Row aria-labelledby="victory">
          <div>
            <H2 id="victory">Choose the Victory Point total</H2>
            <P>
              Next you will choose how many points you are playing to. The
              default is 10, but the app will support scores totals between
              8–20. A pair of quick buttons are provided for selecting between
              the two most common scoring options: 10 and 14.
            </P>
          </div>
          <Img
            src="https://ik.imagekit.io/ti4score/score-selection.png"
            alt="A number input set to 10, showing the total number of points desired for victory"
          />
        </Row>
        <Row aria-labelledby="factions">
          <div>
            <H2 id="factions">Choose factions and select colors</H2>
            <P>
              Now, you will select which factions are playing in this particular
              game. The app will automatically select an appropriate color for
              each faction based on their faction sheet—note that this may lead
              to the same color being selected by default by multiple factions.
            </P>
            <P>
              To change the selected color of a faction, click on its color and
              you will be given a color menu. This menu contains 9 possible
              colors: the 8 standard colors from the game, and white.
            </P>
            <P>
              In addition to selecting factions and colors, you may optionally
              provide a name for the person playing each faction.
            </P>
          </div>
          <Img
            src="https://ik.imagekit.io/ti4score/faction-selection.png"
            alt="A list of six chosen factions, one of whom has their color menu open"
          />
        </Row>
        <Row aria-labelledby="variants">
          <div>
            <H2 id="variants">Optional variants</H2>
            <P>
              At the bottom, below the start button, are multiple game variants
              that may be selected. These are optional, but if you like to play
              Twilight Imperium in a particular way, these are here to help.
            </P>
            <P>
              If you have a suggestion for a new variant you would like to see
              supported, you can send an email to{' '}
              <a href="mailto:ti4score@gmail.com">ti4score@gmail.com</a>.
            </P>
          </div>
          <Img
            src="https://ik.imagekit.io/ti4score/variants.png"
            alt="Variant checkboxes, shown in their untoggled state. One is for the 4-4-4 variant and the other is for the Red Tape variant"
          />
        </Row>
      </Div>
      <h1>Using the Score Tracker</h1>
      <Div>
        <Row aria-labelledby="objective-view">
          <div>
            <H2 id="objective-view">The Objectives View</H2>
            <P>
              After you select the "Begin Game" button, you will see a new
              screen showing 5 Stage 1 objectives, 5 Stage 2 objectives, and
              collapsed boxes for each faction.
            </P>
            <P>
              When played without variants, only the first two objectives are
              active and available. As the game progresses from round to round,
              new objective cards will become available for selection.
            </P>
            <P>
              There are also various game-altering buttons and menus for
              toggling different conditions during your game (such as the
              Classified Document Leaks agenda).
            </P>
          </div>
          <Img
            src="https://ik.imagekit.io/ti4score/new-game-started.png"
            alt="The Objectives view, which shows all available objectives, player scores, and various menu options"
          />
        </Row>
        <Row aria-labelledby="objectives">
          <div>
            <H2 id="objectives">Selecting objectives</H2>
            <P>
              Click on the "card back" for each available objective to view a
              dropdown list of every available objective. Previously selected
              objectives will be disabled, preventing the accidental selection
              of the same objective twice.
            </P>
            <H2>Scoring objectives</H2>
            <P>
              When you need to score one of the revealed objectives, you will
              activate the "Score Objectives" toggle. This replaces the text of
              every available objective with large faction buttons which you can
              then select to indicate a given faction has scored that objective.
            </P>
            <P>
              At the bottom of each objective card you will then see small
              indicators that give a quick snapshot of which factions have
              scored which objectives. These indicators stay visible even when
              you turn off the score toggle.
            </P>
            <P>
              Note: Each time you begin a new round, the toggle will
              automatically turn itself off so that you may select a new
              objective.
            </P>
          </div>
          <div>
            <Img
              src="https://ik.imagekit.io/ti4score/score-objectives-toggle.png"
              alt="Score Objectives toggle in the 'on' state"
            />
            <Img
              src="https://ik.imagekit.io/ti4score/objectives-scoring-on.png"
              alt="Objective cards set to show faction buttons for scoring each objective"
            />
          </div>
        </Row>
        <Row aria-labelledby="chart">
          <div>
            <H2 id="chart">The Score Chart View</H2>
            <P>
              In the top left there is a navigation tab for viewing the score
              chart. As the game progresses, each faction has its score tracked
              from round to round and then displays it on the chart.
            </P>
            <P>
              Individual factions can view their progress on the chart in
              isolation by selecting the colored orb to the right of each
              faction's score. To return to the default view, select the same
              orb.
            </P>
          </div>
          <Img
            src="https://ik.imagekit.io/ti4score/score-chart-all-factions.png"
            alt="The score chart, showing six factions and each of their scores through three rounds of play"
          />
        </Row>
        <Row aria-labelledby="expanding">
          <div>
            <H2 id="expanding">
              Expanding faction boxes and scoring additional points
            </H2>
            <P>
              By default, each faction's score box starts in a collapsed state.
              To expand a given faction, you can simply click on their name.
              This will reveal a more detailed view of which objectives the
              faction has scored as well as let you score additional points,
              such as Secret objectives or the Custodians token.
            </P>
          </div>
          <Img
            src="https://ik.imagekit.io/ti4score/faction-list-one-expanded.png"
            alt="The list of faction boxes, with the first faction's box expanded to show additional scoring options"
          />
        </Row>
        <Row aria-labelledby="agendas">
          <div>
            <H2 id="agendas">Agendas, Relics, and Action Cards</H2>
            <P>
              Throughout the game you are likely to encounter an Agenda, Relic,
              or Action card that will have an impact on one or more players'
              scores. When this happens, you will find the appropriate card
              listed in one of the dropdown menus located above the objectives.
            </P>
            <P>
              After selecting the appropriate card, a modal will open and allow
              you to select which faction(s) are affected by the card.
            </P>
          </div>
          <div>
            <Img
              src="https://ik.imagekit.io/ti4score/agendas-dropdown-menu.png"
              alt="Agenda cards dropdown opened, showing all Agenda cards that impact player score"
            />
            <Img
              src="https://ik.imagekit.io/ti4score/agenda-modal.png"
              alt="The Classified Document Leaks modal, showing the Mahact as the selected faction"
            />
          </div>
        </Row>
        <Row aria-labelledby="save">
          <div>
            <H2 id="save">Saving and Quitting</H2>
            <P>
              Using the main menu in the top right, you are able to save the
              current progress of your game as well as quit to the main screen.
            </P>
            <P>
              Note: Even if you choose to quit your game you are still given the
              option of saving before returning to the main menu.
            </P>
          </div>
          <Img
            src="https://ik.imagekit.io/ti4score/menu-button.png"
            alt="Game menu opened, showing the Save Game and Quit Game buttons"
          />
        </Row>
      </Div>
    </>
  );
};

export default HowItWorks;
