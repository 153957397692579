import React, { useState } from 'react';
import styled from 'styled-components';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { usePlayerStore } from '../../stores/playerStore';
import Button from '../Button';
import Checkbox from '../Checkbox';
import FactionCheckboxes from '../Forms/FactionCheckboxes';
import Radio from '../Radio';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const Radios = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ModalMutiny = () => {
  const [outcome, setOutcome] = useState('for');
  let [votes, setVotes] = useState([]);
  const [scorePoint, players] = usePlayerStore((state) => [
    state.scorePoint,
    state.players,
  ]);
  const [playCard, currentRound] = useGameStore((state) => [
    state.playCard,
    state.currentRound,
  ]);
  const list = players;

  const handleVote = (id) => {
    let tempVotes = [...votes];
    if (id === 'none') {
      if (tempVotes.includes(id)) {
        tempVotes = [];
      } else {
        tempVotes = [];
        tempVotes.push(id);
      }
    } else {
      if (tempVotes.includes(id)) {
        tempVotes = tempVotes.filter((vote) => vote !== id && vote !== 'none');
      } else {
        tempVotes = tempVotes.filter((vote) => vote !== 'none');
        tempVotes.push(id);
      }
    }
    setVotes(tempVotes);
  };

  const handleClick = () => {
    playCard('mutiny', true);
    if (!votes.includes('none')) {
      votes.forEach((vote) =>
        scorePoint(vote, currentRound, 'mutiny', outcome === 'for')
      );
    }
  };
  return (
    <>
      <ModalBody>
        <p>
          Did Mutiny go <strong>FOR</strong> or <strong>AGAINST</strong>?
        </p>
        <Radios>
          <Radio
            id="for"
            name="vote"
            label="For"
            checked={outcome === 'for'}
            onChange={() => setOutcome('for')}
          />
          <Radio
            id="against"
            name="vote"
            label="Against"
            checked={outcome === 'against'}
            onChange={() => setOutcome('against')}
          />
        </Radios>
        <p>
          Who voted <strong>FOR</strong> on the Mutiny agenda?
        </p>
        <Flex>
          <FactionCheckboxes
            list={list}
            onChange={handleVote}
            checked={votes}
          />
          <Checkbox
            id="none"
            label="All factions Abstained or voted Against"
            onChange={() => handleVote('none')}
            checked={votes.includes('none')}
          />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleClick} size="sm" disabled={votes.length === 0}>
          {votes.includes('none') && 'Discard'}
          {outcome === 'for' &&
            !votes.includes('none') &&
            votes.length > 0 &&
            'Gain Mutiny Point'}
          {outcome === 'against' &&
            !votes.includes('none') &&
            votes.length > 0 &&
            'Lose Mutiny Point'}
          {votes.length === 0 && 'Select the Votes'}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalMutiny;
