import { lighten, mix } from 'polished';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { colors } from '../../constants/variables';
import { useGameStore } from '../../stores/gameStore';
import { usePlayerStore } from '../../stores/playerStore';

const Button = styled.button`
  width: ${(props) => (props.count > 6 ? '2.75rem' : '3.25rem')};
  height: ${(props) => (props.count > 6 ? '2.75rem' : '3.25rem')};
  padding: 0;
  margin: 0;
  border-radius: 0.25rem;
  border: ${(props) =>
    `solid 1px ${lighten(0.2, props.active ? props.color : colors.black)}`};
  background-color: ${(props) => (props.active ? props.color : colors.black)};
  opacity: ${(props) => (props.active ? 1 : 0.75)};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: border 0.3s, background-color 0.3s, opacity 0.3s;

  &:hover:not([disabled]) {
    border: solid 1px ${colors.actionColor};
    background-color: ${(props) =>
      `${mix(0.5, colors.actionColor, props.color)}`};
  }
`;

const Img = styled.img`
  width: 2rem;
`;

const FactionButton = ({
  id,
  name,
  icon,
  color,
  count,
  selected,
  scored,
  type,
}) => {
  const [scorePoint] = usePlayerStore((state) => [state.scorePoint]);
  const [currentRound, classifiedRepealed] = useGameStore((state) => [
    state.currentRound,
    state.classifiedRepealed,
  ]);
  const active = scored.includes(selected);
  return (
    <Tooltip background={colors.black} hoverDelay={300} content={name}>
      <Button
        color={color}
        count={count}
        onClick={() => scorePoint(id, currentRound, type, !active, selected)}
        disabled={type === 'classified' && classifiedRepealed}
        active={active}
        aria-label={`${active ? 'Unscore' : 'Score'} objective for ${name}`}
      >
        <Img src={icon} />
      </Button>
    </Tooltip>
  );
};

export default FactionButton;
