import Tooltip from 'react-tooltip-lite';
import styled from 'styled-components';
import { colors } from '../constants/variables';

const Flex = styled.div`
  display: flex;
  gap: 0.25rem;
  justify-content: ${(props) => (props.end === 'end' ? 'flex-end' : null)};
`;

const Tag = styled.div`
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 0.5rem;
  background-color: ${(props) => (props.active ? props.color : colors.midGray)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem;
`;

const Img = styled.img`
  max-height: 100%;
`;

const ScoreTags = ({
  total,
  scored = total,
  color = colors.actionColor,
  type,
  end,
  label = 'Scored Point',
}) => {
  if (!total) return <div></div>;
  return (
    <Tooltip
      hoverDelay={300}
      arrow={false}
      background={colors.black}
      content={label}
    >
      <Flex end={end}>
        {[...Array(total)].map((value, index) => {
          return (
            <Tag key={index} active={index + 1 <= scored} color={color}>
              {type === 'stage_1' && <Img src="/onepoint.svg" alt="" />}
              {type === 'stage_2' && <Img src="/twopoints.svg" alt="" />}
              {type === 'secret' && <Img src="onepoint_secret.svg" alt="" />}
            </Tag>
          );
        })}
      </Flex>
    </Tooltip>
  );
};

export default ScoreTags;
