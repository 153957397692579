import { useState } from 'react';
import { useGameStore } from '../stores/gameStore/gameStore';
import { usePlayerStore } from '../stores/playerStore';
import { useOutsideClick } from '../utils/helpers';
import Dropdown from './Dropdown';
import TextButton from './TextButton';
import RelicIcon from './Graphics/RelicIcon';

const Relics = () => {
  const [show, setShow] = useState(false);
  const [toggleModal, obsidian, crownPurged] = useGameStore((state) => [
    state.toggleModal,
    state.obsidian,
    state.crownPurged,
  ]);
  const players = usePlayerStore((state) => state.players);

  const handleClickOutside = () => {
    setShow(false);
  };

  const obsidianFaction = obsidian
    ? players.filter((player) => player.hasObsidian === true)[0].faction.name
    : undefined;

  const ref = useOutsideClick(handleClickOutside);
  return (
    <Dropdown
      innerRef={ref}
      show={show}
      toggle={() => setShow(!show)}
      label="Relics"
      posX="left"
      mobileIcon={<RelicIcon />}
    >
      <TextButton
        onClick={() => {
          toggleModal('obsidian');
          setShow(!show);
        }}
        disabled={obsidian}
        lg
      >
        {`${
          obsidian ? `Obsidian Gained (${obsidianFaction})` : 'Gain Obsidian'
        }`}
      </TextButton>
      <TextButton
        lg
        onClick={() => {
          toggleModal('shard');
          setShow(!show);
        }}
      >
        Gain Shard of the Throne
      </TextButton>
      <TextButton
        lg
        onClick={() => {
          toggleModal('crown');
          setShow(!show);
        }}
        disabled={crownPurged}
      >
        {crownPurged ? 'Crown of Emphidia Purged' : 'Purge Crown of Emphidia'}
      </TextButton>
    </Dropdown>
  );
};

export default Relics;
