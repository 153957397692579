import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '../constants/variables';

const Container = styled.div`
  width: 100%;
`;

const InputSlider = styled.input`
  appearance: none;
  width: fill-available;
  height: 0.5rem;
  background: ${colors.midGray};
  background-image: linear-gradient(
    ${colors.actionColor},
    ${colors.actionColor}
  );
  background-repeat: no-repeat;
  outline: none;
  border-radius: 0.25rem;
  cursor: pointer;
  border: none;
  transition: box-shadow 0.3s;

  &:focus {
    box-shadow: 0 0 0 0.25rem ${transparentize(0.75, colors.actionColor)};
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: ${colors.actionColor};
    border: none;
    cursor: pointer;
    transition: box-shadow 0.3s;
    &:active,
    &:hover {
      box-shadow: 0 0 0 0.5rem ${transparentize(0.75, colors.actionColor)};
    }
  }

  &::-moz-range-thumb {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: ${colors.actionColor};
    border: none;
    cursor: pointer;
    transition: box-shadow 0.3s;
    &:active,
    &:hover {
      box-shadow: 0 0 0 0.5rem ${transparentize(0.75, colors.actionColor)};
    }
  }

  &::-webkit-slider-runnable-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

const Slider = ({ count, onChange, min, max }) => {
  const arr = [];
  for (let i = min; i <= max; i++) {
    arr.push(i);
  }
  const getBackgroundSize = () => {
    let fillPercent = arr.indexOf(count);
    return {
      backgroundSize: `${(fillPercent * 100) / (arr.length - 1)}% 100%`,
    };
  };

  return (
    <Container>
      <InputSlider
        type="range"
        min={min}
        max={max}
        value={count}
        style={getBackgroundSize()}
        onChange={(e) => onChange(e.target.valueAsNumber)}
      />
    </Container>
  );
};

export default Slider;
