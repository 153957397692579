const ActionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 130.5 133.5"
      width="24px"
      height="24px"
    >
      <g>
        <g>
          <g>
            <polygon points="116.4 46.8 116.4 101.4 62 132.7 62 120.8 102.1 97.7 102.1 50.5 73.6 34.1 73.6 22.1 116.4 46.8" />
            <polygon points="73.6 44.5 73.6 84.6 62.5 78.1 46.1 107.9 14.1 89.4 14.1 30.3 65.2 .8 65.2 17.3 24.2 41 24.2 78.7 40.7 88.2 50.2 71 38.9 64.5 73.6 44.5" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ActionIcon;
