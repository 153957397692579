import { lighten } from 'polished';

export const FACTIONS = [
  {
    id: 0,
    name: 'The Arborec',
    value: 'Arborec',
    icon: '/Arborec.webp',
    version: 'base',
    bg_color: '#5D622B',
    preferred_colors: ['green'],
  },
  {
    id: 1,
    name: 'The Barony of Letnev',
    value: 'Barony',
    icon: '/Barony.webp',
    version: 'base',
    bg_color: '#564B47',
    preferred_colors: ['red', 'black'],
  },
  {
    id: 2,
    name: 'The Clan of Saar',
    value: 'Saar',
    icon: '/Saar.webp',
    version: 'base',
    bg_color: '#6D4D2F',
    preferred_colors: ['orange', 'green', 'yellow'],
  },
  {
    id: 3,
    name: 'The Embers of Muaat',
    value: 'Embers',
    icon: '/Muaat.webp',
    version: 'base',
    bg_color: '#9C652F',
    preferred_colors: ['red', 'orange'],
  },
  {
    id: 4,
    name: 'The Emirates of Hacan',
    value: 'Emirates',
    icon: '/Hacan.webp',
    version: 'base',
    bg_color: '#726823',
    preferred_colors: ['yellow', 'orange'],
  },
  {
    id: 5,
    name: 'The Federation of Sol',
    value: 'Federation',
    icon: '/Sol.webp',
    version: 'base',
    bg_color: '#24667E',
    preferred_colors: ['blue', 'yellow'],
  },
  {
    id: 6,
    name: 'The Ghosts of Creuss',
    value: 'Ghosts',
    icon: '/Ghosts.webp',
    version: 'base',
    bg_color: '#738FA7',
    preferred_colors: ['blue'],
  },
  {
    id: 7,
    name: 'The L1Z1X Mindnet',
    value: 'L1Z1X',
    icon: '/L1Z1X.webp',
    version: 'base',
    bg_color: lighten(0.1, '#274150'),
    preferred_colors: ['black', 'blue', 'red'],
  },
  {
    id: 8,
    name: 'The Mentak Coalition',
    value: 'Mentak',
    icon: '/Mentak.webp',
    version: 'base',
    bg_color: '#906B38',
    preferred_colors: ['orange', 'black', 'yellow'],
  },
  {
    id: 9,
    name: 'The Naalu Collective',
    value: 'Naalu',
    icon: '/Naalu.webp',
    version: 'base',
    bg_color: '#8F6D27',
    preferred_colors: ['green', 'yellow', 'orange'],
  },
  {
    id: 10,
    name: 'The Nekro Virus',
    value: 'Nekro',
    icon: '/Nekro.webp',
    version: 'base',
    bg_color: '#9A312E',
    preferred_colors: ['red'],
  },
  {
    id: 11,
    name: "Sardakk N'orr",
    value: 'Sardakk',
    icon: '/Sardakk.webp',
    version: 'base',
    bg_color: '#40211E',
    preferred_colors: ['black', 'red'],
  },
  {
    id: 12,
    name: 'The Universities of Jol-Nar',
    value: 'Universities',
    icon: '/Jol-Nar.webp',
    version: 'base',
    bg_color: '#613E56',
    preferred_colors: ['blue', 'purple'],
  },
  {
    id: 13,
    name: 'The Winnu',
    value: 'Winnu',
    icon: '/Winnu.webp',
    version: 'base',
    bg_color: '#563347',
    preferred_colors: ['orange', 'yellow', 'purple'],
  },
  {
    id: 14,
    name: 'The Xxcha Kingdom',
    value: 'Xxcha',
    icon: '/Xxcha.webp',
    version: 'base',
    bg_color: '#2C6647',
    preferred_colors: ['green', 'blue'],
  },
  {
    id: 15,
    name: 'The Yin Brotherhood',
    value: 'Yin',
    icon: '/Yin.webp',
    version: 'base',
    bg_color: '#848381',
    preferred_colors: ['purple', 'black', 'yellow'],
  },
  {
    id: 16,
    name: 'The Yssaril Tribes',
    value: 'Yssaril',
    icon: '/Yssaril.webp',
    version: 'base',
    bg_color: '#395F26',
    preferred_colors: ['green', 'yellow'],
  },
  {
    id: 17,
    name: 'The Argent Flight',
    value: 'Argent',
    icon: '/Argent.webp',
    version: 'pok',
    bg_color: '#513728',
    preferred_colors: ['orange'],
  },
  {
    id: 18,
    name: 'The Empyrean',
    value: 'Empyrean',
    icon: '/Empyrean.webp',
    version: 'pok',
    bg_color: '#502E46',
    preferred_colors: ['purple', 'magenta', 'red'],
  },
  {
    id: 19,
    name: 'The Mahact Gene-Sorcerers',
    value: 'Mahact',
    icon: '/Mahact.webp',
    version: 'pok',
    bg_color: '#776651',
    preferred_colors: ['yellow', 'purple'],
  },
  {
    id: 20,
    name: 'The Naaz-Rokha Alliance',
    value: 'NaazRokha',
    icon: '/NaazRokha.webp',
    version: 'pok',
    bg_color: '#B1B675',
    preferred_colors: ['green'],
  },
  {
    id: 21,
    name: 'The Nomad',
    value: 'Nomad',
    icon: '/Nomad.webp',
    version: 'pok',
    bg_color: '#6E81A2',
    preferred_colors: ['blue', 'purple'],
  },
  {
    id: 22,
    name: 'The Titans of Ul',
    value: 'Titans',
    icon: '/Ul.webp',
    version: 'pok',
    bg_color: '#47212E',
    preferred_colors: ['magenta'],
  },
  {
    id: 23,
    name: "The Vuil'Raith Cabal",
    value: 'Vuil_Raith',
    icon: '/Cabal.webp',
    version: 'pok',
    bg_color: '#A83C3A',
    preferred_colors: ['red'],
  },
  {
    id: 24,
    name: 'The Council Keleres',
    value: 'Council',
    icon: '/Keleres.webp',
    version: 'pok',
    bg_color: '#3A1E67',
    preferred_colors: ['purple', 'blue', 'yellow', 'orange'],
  },
];

export const DISCORDANT_FACTIONS = [
  {
    id: 25,
    name: 'The Shipwrights of Axis',
    value: 'Shipwrights',
    icon: '/Axis.webp',
    version: 'discordant',
    bg_color: '#AC8746',
    preferred_colors: ['black', 'red', 'yellow'],
  },
  {
    id: 26,
    name: 'The Celdauri Trade Confederation',
    value: 'Celdauri',
    icon: '/Celdauri.webp',
    version: 'discordant',
    bg_color: '#B4A642',
    preferred_colors: ['yellow', 'blue'],
  },
  {
    id: 27,
    name: 'The Savages of Cymiae',
    value: 'Savages',
    icon: '/Cymiae.webp',
    version: 'discordant',
    bg_color: '#676767',
    preferred_colors: ['orange', 'red', 'blue'],
  },
  {
    id: 28,
    name: 'The Dih-Mohn Flotilla',
    value: 'Dih-Mohn',
    icon: '/Dih-Mohn.webp',
    version: 'discordant',
    bg_color: '#9F28A3',
    preferred_colors: ['magenta', 'red', 'purple'],
  },
  {
    id: 29,
    name: 'The Florzen Profiteers',
    value: 'Florzen',
    icon: '/Florzen.webp',
    version: 'discordant',
    bg_color: '#379B9C',
    preferred_colors: ['blue', 'green', 'orange'],
  },
  {
    id: 30,
    name: 'The Free Systems Compact',
    value: 'Free_Systems',
    icon: '/Free_Systems.webp',
    version: 'discordant',
    bg_color: '#409783',
    preferred_colors: ['purple', 'magenta', 'green'],
  },
  {
    id: 31,
    name: 'The Ghemina Raiders',
    value: 'Ghemina',
    icon: '/Ghemina.webp',
    version: 'discordant',
    bg_color: '#808A90',
    preferred_colors: ['blue', 'black'],
  },
  {
    id: 32,
    name: 'The Augurs of Ilyxum',
    value: 'Augurs',
    icon: '/Ilyxum.webp',
    version: 'discordant',
    bg_color: '#743D6D',
    preferred_colors: ['purple', 'orange'],
  },
  {
    id: 33,
    name: 'The Kollecc Society',
    value: 'Kollecc',
    icon: 'Kollecc.webp',
    version: 'discordant',
    bg_color: '#9C632F',
    preferred_colors: ['orange', 'black', 'red'],
  },
  {
    id: 34,
    name: 'The Kortali Tribunal',
    value: 'Kortali',
    icon: '/Kortali.webp',
    version: 'discordant',
    bg_color: '#484B9A',
    preferred_colors: ['black', 'purple'],
  },
  {
    id: 35,
    name: 'The Li-Zho Dynasty',
    value: 'Li-Zho',
    icon: '/Li-Zho.webp',
    version: 'discordant',
    bg_color: '#8D6525',
    preferred_colors: ['black', 'orange'],
  },
  {
    id: 36,
    name: "The L'tokk Khrask",
    value: 'L_tokk',
    icon: '/Khrask.webp',
    version: 'discordant',
    bg_color: '#6B513A',
    preferred_colors: ['green', 'orange', 'blue'],
  },
  {
    id: 37,
    name: 'The Mirveda Protectorate',
    value: 'Mirveda',
    icon: '/Mirveda.webp',
    version: 'discordant',
    bg_color: '#8A374A',
    preferred_colors: ['magenta', 'blue'],
  },
  {
    id: 38,
    name: 'The Glimmer of Mortheus',
    value: 'Glimmer',
    icon: '/Mortheus.webp',
    version: 'discordant',
    bg_color: '#8F316E',
    preferred_colors: ['magenta', 'blue', 'red'],
  },
  {
    id: 39,
    name: 'The Myko-Mentori',
    value: 'Myko',
    icon: '/Myko.webp',
    version: 'discordant',
    bg_color: '#988829',
    preferred_colors: ['green', 'magenta', 'orange'],
  },
  {
    id: 40,
    name: 'The Nivyn Star Kings',
    value: 'Nivyn',
    icon: 'Nivyn.webp',
    version: 'discordant',
    bg_color: '#949494',
    preferred_colors: ['black', 'yellow'],
  },
  {
    id: 41,
    name: 'The Olradin League',
    value: 'Olradin',
    icon: '/Olradin.webp',
    version: 'discordant',
    bg_color: '#05B6C5',
    preferred_colors: ['orange', 'blue', 'yellow'],
  },
  {
    id: 42,
    name: 'The Zealots of Rhodun',
    value: 'Zealots',
    icon: '/Rhodun.webp',
    version: 'discordant',
    bg_color: '#40829E',
    preferred_colors: ['black', 'blue'],
  },
  {
    id: 43,
    name: "Roh'Dhna Mechatronics",
    value: 'Roh_Dhna',
    icon: '/Roh_Dhna.webp',
    version: 'discordant',
    bg_color: '#828152',
    preferred_colors: ['blue', 'black'],
  },
  {
    id: 44,
    name: 'The Tnelis Syndicate',
    value: 'Tnelis',
    icon: '/Tnelis.webp',
    version: 'discordant',
    bg_color: '#846120',
    preferred_colors: ['red', 'green', 'yellow'],
  },
  {
    id: 45,
    name: 'The Vaden Banking Clans',
    value: 'Vaden',
    icon: '/Vaden.webp',
    version: 'discordant',
    bg_color: '#20A136',
    preferred_colors: ['green', 'yellow'],
  },
  {
    id: 46,
    name: 'The Vaylerian Scourge',
    value: 'Vaylerian',
    icon: '/Vaylerian.webp',
    version: 'discordant',
    bg_color: '#3CAFA9',
    preferred_colors: ['blue', 'green'],
  },
  {
    id: 47,
    name: 'The Veldyr Sovereignty',
    value: 'Veldyr',
    icon: '/Veldyr.webp',
    version: 'discordant',
    bg_color: '#1D3944',
    preferred_colors: ['blue', 'black'],
  },
  {
    id: 48,
    name: 'The Zelian Purifier',
    value: 'Zelian',
    icon: '/Zelian.webp',
    version: 'discordant',
    bg_color: '#A96241',
    preferred_colors: ['Red'],
  },
  {
    id: 49,
    name: 'The Bentor Conglomerate',
    value: 'Bentor',
    icon: '/Bentor.webp',
    version: 'discordant',
    bg_color: '#4C31A1',
    preferred_colors: ['Purple', 'Blue', 'Magenta'],
  },
  {
    id: 50,
    name: 'The Cheiran Hordes',
    value: 'Cheiran',
    icon: '/Cheiran.webp',
    version: 'discordant',
    bg_color: '#2F313E',
    preferred_colors: ['Orange', 'Blue'],
  },
  {
    id: 51,
    name: 'The Edyn Mandate',
    value: 'Edyn',
    icon: '/Edyn.webp',
    version: 'discordant',
    bg_color: '#6A531B',
    preferred_colors: ['Black', 'Yellow'],
  },
  {
    id: 52,
    name: 'The Ghoti Wayfarers',
    value: 'Ghoti',
    icon: '/Ghoti.webp',
    version: 'discordant',
    bg_color: '#38818D',
    preferred_colors: ['Blue', 'Green', 'Orange'],
  },
  {
    id: 53,
    name: 'The Gledge Union',
    value: 'Gledge',
    icon: '/Gledge.webp',
    version: 'discordant',
    bg_color: '#6F4D30',
    preferred_colors: ['Orange', 'Green'],
  },
  {
    id: 54,
    name: 'The Beserkers of Kjalengard',
    value: 'Beserkers',
    icon: '/Kjalengard.webp',
    version: 'discordant',
    bg_color: '#281D71',
    preferred_colors: ['Blue', 'Magenta', 'Red'],
  },
  {
    id: 55,
    name: 'The Monks of Kolume',
    value: 'Monks',
    icon: '/Kolume.webp',
    version: 'discordant',
    bg_color: '#505C66',
    preferred_colors: ['Black', 'Orange'],
  },
  {
    id: 56,
    name: 'The Kyro Sodality',
    value: 'Kyro',
    icon: '/Kyro.webp',
    version: 'discordant',
    bg_color: '#3A7655',
    preferred_colors: ['Green', 'Blue'],
  },
  {
    id: 57,
    name: 'The Lanefir Remnants',
    value: 'Lanefir',
    icon: '/Lanefir.webp',
    version: 'discordant',
    bg_color: '#378D58',
    preferred_colors: ['Orange', 'Green'],
  },
  {
    id: 58,
    name: 'The Nokar Sellships',
    value: 'Nokar',
    icon: '/Nokar.webp',
    version: 'discordant',
    bg_color: '#97522A',
    preferred_colors: ['Orange', 'Blue'],
  },
  {
    id: 59,
    name: 'The Drahn Consortium',
    value: 'Drahn',
    icon: '/Drahn.webp',
    version: 'discordant',
    bg_color: '#98130D',
    preferred_colors: ['Red', 'Orange', 'Yellow'],
  },
];
