import { useEffect } from 'react';
import { usePlayerStore } from '../../stores/playerStore';
import ElectRow from './ElectRow';
import { useGameStore } from '../../stores/gameStore';
import { STRATEGIES } from '../../constants/strategies';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const ElectBlock = ({ elect }) => {
  const [players, playerCount] = usePlayerStore((state) => [
    state.players,
    state.playerCount,
  ]);
  const [buildVoteElect, votes, selectedAgenda] = useGameStore((state) => [
    state.buildVoteElect,
    state.votes,
    state.selectedAgenda,
  ]);

  useEffect(() => {
    const rows = [];
    if (elect === 'Player') {
      for (let i = 0; i < playerCount; i++) {
        rows.push({
          id: i,
          name: players[i].faction.name,
          votes: 0,
        });
      }
    } else if (elect === 'Strategy Card') {
      for (let i = 0; i < STRATEGIES.length; i++) {
        rows.push({
          id: i,
          name: STRATEGIES[i].name,
          votes: 0,
        });
      }
    } else {
      for (let i = 0; i < playerCount; i++) {
        rows.push({
          id: i,
          name: '',
          votes: 0,
        });
      }
    }
    buildVoteElect(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgenda]);
  return (
    <Div>
      {votes.elect.map((option) => {
        return <ElectRow key={option.id} option={option} elect={elect} />;
      })}
    </Div>
  );
};

export default ElectBlock;
