import styled from 'styled-components';
import { titleText } from '../utils/helpers';
import LinkButton from '../components/LinkButton';
import { breakpoints, colors, fonts } from '../constants/variables';
import Button from '../components/Button';
import { useState } from 'react';
import BackgroundPicker from '../components/BackgroundPicker';

const GoBack = styled(LinkButton)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const H1 = styled.h1`
  font-family: ${fonts.title};
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;

  span {
    font-family: ${fonts.fancy};
    font-weight: 300;
    text-transform: none;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 1.25rem;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
`;

const Li = styled.li`
  margin: 0;
  padding: 0;
`;

const LeftButton = styled(Button)`
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: ${(props) =>
    props.active ? 'solid 1px transparent' : `solid 1px ${colors.actionColor}`};
  margin: 0;
`;

const RightButton = styled(Button)`
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: ${(props) =>
    props.active ? 'solid 1px transparent' : `solid 1px ${colors.actionColor}`};
  margin: 0;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BackgroundView = () => {
  titleText('Choose a Background - ti4score');
  const [tab, setTab] = useState('official');
  return (
    <>
      <H1>
        <span>Choose a Background</span>
      </H1>
      <GoBack outline size="sm" square to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
        </svg>
      </GoBack>
      <Flex>
        <Ul>
          <Li>
            <LeftButton
              size="xs"
              active={tab === 'official'}
              outline
              onClick={() => setTab('official')}
            >
              Official Art
            </LeftButton>
          </Li>
          <Li>
            <RightButton
              size="xs"
              active={tab === 'unofficial'}
              outline
              onClick={() => setTab('unofficial')}
            >
              Fan Creations
            </RightButton>
          </Li>
        </Ul>
      </Flex>
      <BackgroundPicker type={tab} />
    </>
  );
};

export default BackgroundView;
