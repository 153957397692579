import styled from 'styled-components';
import PlayerRow from './PlayerRow';
import AgendaSection from './AgendaSection';

const VoteTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const H1 = styled.h1`
  margin: 0;
`;

const Agenda = () => {
  return (
    <VoteTable>
      <Flex>
        <H1>Votes Available</H1>
        <PlayerRow />
      </Flex>
      <AgendaSection />
    </VoteTable>
  );
};

export default Agenda;
