import React, { useState } from 'react';
import { useGameStore } from '../../stores/gameStore/gameStore';
import { usePlayerStore } from '../../stores/playerStore';
import Button from '../Button';
import FactionCheckboxes from '../Forms/FactionCheckboxes';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const ModalSeed = () => {
  let [seeds, setSeeds] = useState([]);
  const [scorePoint, players] = usePlayerStore((state) => [
    state.scorePoint,
    state.players,
  ]);
  const [playCard, currentRound] = useGameStore((state) => [
    state.playCard,
    state.currentRound,
  ]);
  const list = players;

  const handleClick = (id) => {
    let tempSeeds = [...seeds];
    if (tempSeeds.includes(id)) {
      tempSeeds = tempSeeds.filter((seed) => seed !== id);
    } else {
      tempSeeds.push(id);
    }
    setSeeds(tempSeeds);
  };

  const submit = () => {
    playCard('seedOfEmpire', true);
    seeds.forEach((seed) => scorePoint(seed, currentRound, 'seed', true));
  };
  return (
    <>
      <ModalBody>
        <p>
          Which faction(s) are <strong>GAINING</strong> a victory point?
        </p>
        <FactionCheckboxes list={list} onChange={handleClick} checked={seeds} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={submit} size="sm">
          Gain Victory Point
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalSeed;
