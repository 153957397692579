import styled from 'styled-components';
import VoterBlock from './VoterBlock';
import { usePlayerStore } from '../../stores/playerStore';
import { breakpoints } from '../../constants/variables';
import _ from 'lodash';

const Div = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media only screen and (max-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PlayerRow = () => {
  const [players] = usePlayerStore((state) => [state.players]);
  const sortedPlayers = _.orderBy(players, 'totalPoints', 'desc');
  return (
    <Div>
      {sortedPlayers[0] && <VoterBlock player={sortedPlayers[0]} />}
      {sortedPlayers[1] && <VoterBlock player={sortedPlayers[1]} />}
      {sortedPlayers[2] && <VoterBlock player={sortedPlayers[2]} />}
      {sortedPlayers[3] && <VoterBlock player={sortedPlayers[3]} />}
      {sortedPlayers[4] && <VoterBlock player={sortedPlayers[4]} />}
      {sortedPlayers[5] && <VoterBlock player={sortedPlayers[5]} />}
      {sortedPlayers[6] && <VoterBlock player={sortedPlayers[6]} />}
      {sortedPlayers[7] && <VoterBlock player={sortedPlayers[7]} />}
    </Div>
  );
};

export default PlayerRow;
