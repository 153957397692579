export const SECRETS = [
  {
    name: 'Destroy Their Greatest Ship',
    text: "Destroy another player's war sun or flagship",
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Make an Example of Their World',
    text: "Destroy the last of a player's ground forces on a planet during the bombardment step",
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Spark a Rebellion',
    text: 'Win a combat against a player who has the most victory points',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Turn Their Fleets to Dust',
    text: "Destroy the last of a player's non-fighter ships in the active system during the space cannon offense step",
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Unveil Flagship',
    text: 'Win a space combat in a system that contains your flagship. You cannot score this objective if your flagship is destroyed in the combat',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Become a Martyr',
    text: 'Lose control of a planet in a home system',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Betray a Friend',
    text: 'Win a combat against a player whose promissory note you had in your play area at the start of your tactical action',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Brave the Void',
    text: 'Win a combat in an anomaly',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Darken the Skies',
    text: "Win a combat in another player's home system",
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Demonstrate Your Power',
    text: 'Have 3 or more non-fighter ships in the active system at the end of a space combat',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Fight With Precision',
    text: "Destroy the last of a player's fighters in the active system during the anti-fighter barrage step",
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Prove Endurance',
    text: 'Be the last player to pass during a game round',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'action',
    scored_by: [],
  },
  {
    name: 'Adapt New Strategies',
    text: 'Own 2 faction technologies',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Become the Gatekeeper',
    text: 'Have 1 or more ships in a system that contains an alpha wormhole, and 1 or more ships in a system that contains a beta wormhole',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Control the Region',
    text: 'Have 1 or more ships in 6 systems',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Cut Supply Lines',
    text: "Have 1 or more ships in the same system as another player's space dock",
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Establish a Perimeter',
    text: 'Have 4 PDS units on the game board',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Forge an Alliance',
    text: 'Control 4 cultural planets',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Form a Spy Network',
    text: 'Discard 5 action cards',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Fuel the War Machine',
    text: 'Have 3 space docks on the game board',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Gather a Mighty Fleet',
    text: 'Have 5 dreadnoughts on the game board',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Learn the Secrets of the Cosmos',
    text: 'Have 1 or more ships in 3 systems that are each adjacent to an anomaly',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Master the Laws of Physics',
    text: 'Own 4 technologies of the same color',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Mine Rare Metals',
    text: 'Control 4 hazardous planets',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Monopolize Production',
    text: 'Control 4 industrial planets',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Occupy the Seat of the Empire',
    text: 'Control Mecatol Rex and have 3 or more ships in its system',
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Threaten Enemies',
    text: "Have 1 or more ships in a system that is adjacent to another player's home system",
    value: 1,
    expansion: false,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Defy Space and Time',
    text: 'Have units in the wormhole nexus',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Destroy Heretical Works',
    text: 'Purge 2 of your relic fragments of any type',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Establish Hegemony',
    text: 'Control planets that have a combined influence value of at least 12',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Foster Cohesion',
    text: 'Be neighbors will all other players',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'How Raw Materials',
    text: 'Control planets that have a combined resource value of at least 12',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Mechanize the Military',
    text: 'Have 1 mech on each of 4 planets',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Occupy the Fringe',
    text: 'Have 9 or more ground forces on a planet that does not contain 1 of your space docks',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Produce En Masse',
    text: 'Have units with a combined Production value of at least 8 in a single system',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Seize an Icon',
    text: 'Control a legendary planet',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Stake Your Claim',
    text: 'Control a planet in a system that contains a planet controlled by another player',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Strengthen Bonds',
    text: "Have another player's promissory note in your play area",
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Dictate Policy',
    text: 'There are 3 or more laws in play',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'agenda',
    scored_by: [],
  },
  {
    name: 'Drive the Debate',
    text: 'You or a planet you control are elected by an agenda',
    value: 1,
    expansion: true,
    type: 'secret',
    phase: 'agenda',
    scored_by: [],
  },
];

export const PUBLIC = [
  {
    name: 'Corner the Market',
    text: 'Control 4 planets that each have the same planet trait.',
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Develop Weaponry',
    text: 'Own 2 unit upgrade technologies.',
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Diversify Research',
    text: 'Own 2 technologies in each of 2 colors.',
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Erect a Monument',
    text: 'Spend 8 resources.',
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Expand Borders',
    text: 'Control 6 planets in non-home systems.',
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Found Research Outposts',
    text: 'Control 3 planets that have technology specialties.',
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Intimidate Council',
    text: "Have 1 or more ships in 2 systems that are adjacent to Mecatol Rex's System.",
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Lead from the Front',
    text: 'Spend a total of 3 tokens from your tactic and/or strategy pools.',
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Negotiate Trade Routes',
    text: 'Spend 5 trade goods.',
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Sway the Council',
    text: 'Spend 8 influence.',
    value: 1,
    expansion: false,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Amass Wealth',
    text: 'Spend 3 influence, 3 resources, and 3 trade goods.',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Build Defenses',
    text: 'Have 4 or more structures (on the game board).',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Discover Lost Outposts',
    text: 'Control 2 planets that have attachments.',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Engineer a Marvel',
    text: 'Have your flagship or a war sun on the game board.',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Explore Deep Space',
    text: 'Have units in 3 systems that do not contain planets.',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Improve Infrastructure',
    text: 'Have structures on 3 planets outside of your home system.',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Make History',
    text: 'Have units in 2 systems that contain legendary planets, Mecatol Rex, or anomalies.',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Populate the Outer Rim',
    text: 'Have units in 3 systems on the edge of the game board (other than your home system).',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Push Boundaries',
    text: 'Control more planets than each of 2 of your neighbors.',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Raise a Fleet',
    text: 'Have 5 or more non-fighter ships in one system.',
    value: 1,
    expansion: true,
    type: 'stage_1',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Centralize Galactic Trade',
    text: 'Spend 10 trade goods.',
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Conquer the Weak',
    text: "Control 1 planet that is in another player's home system.",
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Form Galactic Brain Trust',
    text: 'Control 5 planets that have a technology specialty.',
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Found a Golden Age',
    text: 'Spend 16 resources.',
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Galvanize the People',
    text: 'Spend a total of 6 tokens from your tactic and/or strategy pools.',
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Manipulate Galactic Law',
    text: 'Spend 16 influence.',
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Master the Sciences',
    text: 'Own 2 technologies in each of 4 colors.',
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Revolutionize Warfare',
    text: 'Own 3 unit upgrade technologies.',
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Subdue the Galaxy',
    text: 'Control 11 planets in non-home systems.',
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Unify the Colonies',
    text: 'Control 6 planets that each have the same planet trait.',
    value: 2,
    expansion: false,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Achieve Supremacy',
    text: "Have your flagship or a war sun in another player's home system or the Mecatol Rex system.",
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Become a Legend',
    text: 'Have units in 4 systems that contain legendary planets, Mecatol Rex, or anomalies.',
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Command an Armada',
    text: 'Have 8 or more non-fighter ships in 1 system.',
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Construct Massive Cities',
    text: 'Have 7 or more structures (on the game board).',
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Control the Borderlands',
    text: 'Have units in 5 systems on the edge of the game board (other than your home system).',
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Hold Vast Reserves',
    text: 'Spend 6 influence, 6 resources, and 6 trade goods.',
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Patrol Vast Territories',
    text: 'Have units in 5 systems that do not contain planets.',
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Protect the Border',
    text: 'Have structures on 5 planets outside of your home system.',
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Reclaim Ancient Monuments',
    text: 'Control 3 planets that have attachments.',
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
  {
    name: 'Rule Distant Lands',
    text: "Control 2 planets that are each in or adjacent to a different, other player's home system.",
    value: 2,
    expansion: true,
    type: 'stage_2',
    phase: 'status',
    scored_by: [],
  },
];
