import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors, fonts } from '../../constants/variables';
import { useGameStore } from '../../stores/gameStore/gameStore';
import Button from '../Button';

const ModalFlex = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50em;
  width: 30em;
  background-color: ${colors.cardBack};
  border: solid 1px ${transparentize(0.75, colors.actionColor)};
  border-radius: 0.25rem;

  @media only screen and (max-width: ${breakpoints.xs}) {
    height: 100%;
    border-radius: 0;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${transparentize(0.5, colors.cardBack)};
  z-index: 20;
`;

const ModalHeader = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: solid 1px ${transparentize(0.9, colors.white)};
  align-items: center;
`;

const H1 = styled.h1`
  font-family: ${fonts.standard};
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
`;

const Modal = (props) => {
  // eslint-disable-next-line no-unused-vars
  const toggleModal = useGameStore((state) => state.toggleModal);
  const { children, title } = props;
  return (
    <ModalContainer>
      <ModalFlex>
        <ModalHeader aria-labelledby="title">
          <H1 id="title">{title}</H1>
          <Button
            aria-label="Close Modal"
            onClick={() => toggleModal()}
            size="sm"
            square
            outline
            autoFocus
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill={colors.actionColor}
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </Button>
        </ModalHeader>
        {children}
      </ModalFlex>
    </ModalContainer>
  );
};

export default Modal;
