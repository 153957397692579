import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  padding: 4rem;
  max-width: 25em;
  text-align: center;
  margin: 0 auto;
`;

const Img = styled.img`
  max-width: 3rem;
`;

const EmptyState = ({ title, text }) => {
  return (
    <Flex>
      <Img src="/commodities.webp" />
      <h1>{title}</h1>
      <span>{text}</span>
    </Flex>
  );
};

export default EmptyState;
