import { useState, useEffect } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import VisitCounter from '../components/VisitCounter';
import { db } from '../firebase';
import { hasVisited, setVisitedCookie } from '../utils/cookies';

const COLLECTION_ID = 'visitor-count';
const CURRENT_COUNT_ID = '2023-03-08-visit-count';

// TODO: set/read cookie if already visited page
// in order to prevent same client being counted multiple times

const VisitCounterContainer = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, COLLECTION_ID, CURRENT_COUNT_ID);
      const countRef = await getDoc(docRef);
      if (countRef.exists()) {
        const curCount = countRef.data().count;
        // set our count state
        setCount(curCount);
        setLoading(false);
        if (!hasVisited()) {
          // iterate our current count
          setCount(curCount + 1);
          setVisitedCookie();
          // set the new count in firestore
          await setDoc(docRef, {
            count: curCount + 1,
          });
        }
      } else {
        setLoading(false);
        console.error('Error loading firestore visitor count!');
      }
    };
    fetchData();
  }, []);
  return <VisitCounter total={count} loading={loading} />;
};

export default VisitCounterContainer;
