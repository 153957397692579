import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LinkButton from '../components/LinkButton';
import { breakpoints, colors, fonts } from '../constants/variables';
import { titleText } from '../utils/helpers';

const GoBack = styled(LinkButton)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const H1 = styled.h1`
  font-family: ${fonts.title};
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;

  span {
    font-family: ${fonts.fancy};
    font-weight: 300;
    text-transform: none;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 1.25rem;
  }
`;

const Div = styled.div`
  text-align: left;
  max-width: 100%;
`;

const H2 = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  white-space: normal;

  &:not(:first-of-type) {
    margin-top: 3rem;
  }
`;

const P = styled.p`
  max-width: 75ch;
  line-height: 1.5;
`;

const Img = styled.img`
  max-width: 100%;
`;

const Nav = styled.nav`
  border: solid 1px ${transparentize(0.5, colors.border)};
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
`;

const Header = styled.header`
  font-size: 1.5rem;
`;

const Ul = styled.ul`
  margin-left: 1rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  a {
    color: ${colors.actionColor};
  }
`;

const FAQs = () => {
  titleText('Frequently Asked Questions - ti4score');
  return (
    <>
      <H1>
        <span>Frequently Asked Questions</span>
      </H1>
      <GoBack outline size="sm" square to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
        </svg>
      </GoBack>
      <Div>
        <Nav aria-labelledby="header">
          <Header id="header">Page Contents</Header>
          <Ul>
            <li>
              <a href="#what">What is this score tracker?</a>
            </li>
            <li>
              <a href="#features">What are the features and capabilities?</a>
            </li>
            <li>
              <a href="#expansion">
                Does it support the expansion, Prophecy of Kings?
              </a>
            </li>
            <li>
              <a href="#when">When would I use this tracker?</a>
            </li>
            <li>
              <a href="#started">How do I get started?</a>
            </li>
            <li>
              <a href="#save">
                Can I save my games and come back to them later?
              </a>
            </li>
            <li>
              <a href="#stored">How are saved games stored?</a>
            </li>
            <li>
              <a href="#load">Can I load my games from any device?</a>
            </li>
            <li>
              <a href="#multiple">
                Can multiple users log in and view the same game at the same
                time?
              </a>
            </li>
            <li>
              <a href="#red">What is the red area when viewing the chart?</a>
            </li>
            <li>
              <a href="#email">
                How can I submit a bug report or feature request?
              </a>
            </li>
          </Ul>
        </Nav>
        <section aria-label="Questions">
          <H2 id="what">What is this score tracker?</H2>
          <P>
            This score tracker is used for keeping track of each player's score
            during a game of Twilight Imperium, 4th Edition. Specifically, it is
            capable of showing all revealed objectives and allows the ability to
            select which factions have scored them.
          </P>
          <P>
            It was built to be easy-to-use, accessible, beautiful,
            mobile-friendly, and intuitive. Another guiding principle was to be
            as unopinionated as possible regarding the rules and what is
            allowed. Because there are so many variants to Twilight Imperium,
            and because this tool cannot support all of them, it made sense to
            leave a lot of flexibility in the app.
          </P>
          <P>
            It is not intended as a replacement for the entire game, nor does it
            offer the ability to track turn order or strategy card selection.
            However, if it's score related, you can probably find a feature for
            it here.
          </P>
          <H2 id="features">What are the features and capabilities?</H2>
          <P>With this score tracker you can do the following:</P>
          <ul>
            <li>
              Select the chosen faction for each player (from 2–8 players,
              defaults to 6)
            </li>
            <li>Type in each player's name and choose their faction color</li>
            <li>
              Select a Victory Point requirement for winning (from 8–20,
              defaults to 10)
            </li>
            <li>
              Track current objectives and score them individually for each
              faction
            </li>
            <li>Activate all scoreable Agendas, Relics, and Action Cards</li>
            <li>
              Score Custodians, Imperial, Secret objective, and Support for the
              Throne points
            </li>
            <li>Progress from round to round</li>
            <li>View a chart of player score progress</li>
            <li>Save and load games</li>
          </ul>
          <H2 id="expansion">
            Does it support the expansion, Prophecy of Kings?
          </H2>
          <P>
            Yes it does! Along with the expansion, it also supports all
            currently-released factions and codices.
          </P>
          <H2 id="when">When would I use this tracker?</H2>
          <P>
            This score tracker can be used for both in-person and digital games
            of Twilight Imperium.
          </P>
          <P>
            Do you find yourself currently tracking scores on a whiteboard or
            something similar? If so, this tool is for you. Grab a tablet or
            laptop, hook it up to your TV, and project the scores and objectives
            for everyone to see!
          </P>
          <H2 id="started">How do I get started?</H2>
          <P>
            One of the main goals was to create an app that was intuitive enough
            to use without instruction. However, if you want to know all of the
            ins-and-outs, we have an entire <Link to="/how-it-works">page</Link>{' '}
            dedicated to showing you how to use this web app. Check it out!
          </P>
          <H2 id="save">Can I save my games and come back to them later?</H2>
          <P>
            Yes! We've included the ability to save your games just in case you
            need to take a break. You can find the Save button in the top-right
            Menu dropdown.
          </P>
          <img
            src="https://ik.imagekit.io/ti4score/menu-button.png"
            alt="Game menu toggled open, showing the 'Save Game' and 'Quit Game' buttons"
          />
          <P>
            Please note that the app receives regular updates to make it better
            all the time. Because of that, older saved games may not always
            behave as expected.
          </P>
          <H2 id="stored">How are saved games stored?</H2>
          <P>
            Currently, we use localStorage, which is a browser-based solution.
            Long-term, we would like to move towards a proper database solution,
            which would allow for users to load their games from any device and
            provide a more robust experience.
          </P>
          <P>
            You can learn more about localStorage on the{' '}
            <a
              href="https://www.w3schools.com/jsref/prop_win_localstorage.asp"
              target="_blank"
              rel="noreferrer noopener"
            >
              W3 Schools
            </a>{' '}
            website.
          </P>
          <H2 id="load">Can I load my games from any device?</H2>
          <P>
            No, not currently. Because of the limitations of localStorage, you
            are required to use the same device for loading previous games.
            However, the application is extremely mobile-friendly, so you can
            start new games on any device you like.
          </P>
          <H2 id="multiple">
            Can multiple users log in and view the same game at the same time?
          </H2>
          <P>
            Not at the moment. Instead, we would suggest having one person
            designated as the score tracker. This person can then project their
            device onto something like a TV for everyone to view.
          </P>
          <H2 id="red">What is the red area when viewing the chart?</H2>
          <P>
            This is the expected score progress from round to round. It is
            semi-arbitrary in that it is based on a limited number of games of
            Twilight Imperium. Regardless, it is a helpful tool when assessing
            the current state of the game board and determining who is most
            likely to win.
          </P>
          <Img
            src="https://ik.imagekit.io/ti4score/tr:w-800/score-chart-all-factions.png"
            alt="Score chart showing the scores of six different factions through round 3"
          />
          <H2 id="email">How can I submit a bug report or feature request?</H2>
          <P>
            You can send an email to{' '}
            <a href="mailto:ti4score@gmail.com">ti4score@gmail.com</a>. We would
            absolutely love to hear from you—the good, the bad, and the ugly.
          </P>
        </section>
      </Div>
    </>
  );
};

export default FAQs;
