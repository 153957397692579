import { useRouteError } from 'react-router-dom';
import { titleText } from '../utils/helpers';

const Error = () => {
  const error = useRouteError();
  titleText(error.statusText);
  return (
    <>
      <h1>{error.statusText}</h1>
      <p>{error.message}</p>
    </>
  );
};

export default Error;
