import { lighten, transparentize } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, colors, fonts } from '../constants/variables';
import { useGameStore } from '../stores/gameStore/gameStore';
import { usePlayerStore } from '../stores/playerStore';
import ObjectiveBlock from './ObjectiveBlock';
import ScoreBlock from './ScoreBlock';
import BonusBlock from './BonusBlock';
import Button from './Button';
import ScoreTags from './ScoreTags';
import _ from 'lodash';

const Block = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (!props.localExpand ? '0' : '1rem')};
  padding: 0.75rem 0.5rem;
  border: solid 1px
    ${(props) =>
      props.color === 'black' ? lighten(0.1, props.color) : props.color};
  background-color: ${(props) => transparentize(0.95, props.color)};

  @media only screen and (max-width: ${breakpoints.sm}) {
    padding: 0.5rem;
  }
`;

const Details = styled.div`
  display: flex;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: ${(props) => (props.align === 'end' ? 'flex-end' : 'initial')};
`;

const Color = styled.button`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  background-color: ${(props) => (props.focused ? props.color : 'transparent')};
  border: solid 1px ${(props) => lighten(0.15, props.color)};
  opacity: ${(props) => (props.focused ? 1 : 0.5)};
  transition: opacity 0.3s, box-shadow 0.3s, background-color 0.3s;

  &:hover {
    cursor: pointer;
    opacity: ${(props) => (props.focused ? 1 : 0.75)};
    box-shadow: 0 0 0 3px ${(props) => transparentize(0.5, props.color)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${(props) => transparentize(0.5, props.color)};
  }
`;

const H1 = styled.h1`
  margin: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.75rem;

  @media only screen and (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl}) {
    font-size: 1.25rem;
  }

  @media only screen and (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.lg}) {
    font-size: 1.1rem;
  }

  @media only screen and (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.sm}) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 1rem;
  }
`;

const Img = styled.img`
  max-height: 1.75rem;

  @media only screen and (max-width: ${breakpoints.xs}) {
    max-height: 1.25rem;
  }
`;

const Svg = styled.img`
  transition: transform 0.15s;
  transform: ${(props) => (props.expanded ? 'rotate(90deg)' : null)};
`;

const H2 = styled.h2`
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;
  font-family: ${fonts.mono};
`;

const Span = styled.span`
  margin-left: 2.25rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  > *:last-child:not(:only-child) {
    justify-content: flex-end;
  }

  > * {
    align-self: start;
  }
`;

const Flex = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const FlexBalls = styled.div`
  display: grid;
  grid-template-columns: 6fr 6fr 4fr 2fr 3fr;
  grid-gap: 0.5rem;
`;

const FacButton = styled.button`
  color: ${colors.white};
  background-color: transparent;
  font-family: ${fonts.fancy};
  border: none;
  padding: 0 1rem 0 0;
  margin: 0;
  opacity: ${(props) => (props.focused ? 1 : 0.5)};
  transition: opacity 0.3s, text-shadow 0.3s;
  flex: 1;
  text-align: start;

  &:hover {
    cursor: pointer;
    opacity: ${(props) => (props.focused ? 1 : 0.75)};
    text-shadow: 0 0 3px ${transparentize(0.5, colors.white)};
  }

  &:focus {
    outline: none;
    text-shadow: 0 0 3px ${transparentize(0.5, colors.white)};
  }
`;

const FactionScorer = React.forwardRef(
  ({ player, focused, focusFaction }, ref) => {
    const [localExpand, setLocalExpand] = useState(false);
    const [playerCount] = usePlayerStore((state) => [state.playerCount]);
    const [
      currentRound,
      availStage2,
      maxSecret,
      scoreLimit,
      expanded,
      custodiansClaimed,
      misc,
      toggleModal,
      setCustodianId,
    ] = useGameStore((state) => [
      state.currentRound,
      state.availStage2,
      state.maxSecret,
      state.scoreLimit,
      state.expanded,
      state.custodiansClaimed,
      state.misc,
      state.toggleModal,
      state.setCustodianId,
    ]);

    useEffect(() => {
      setLocalExpand(expanded);
    }, [expanded]);

    const toggleCustodianModal = () => {
      setCustodianId(player.id);
      toggleModal('custodians');
    };

    // need to add up the total number of bonus points
    const playerPT = player.point_by_type;
    let bonusTotal = 0;
    bonusTotal +=
      playerPT.custodians +
      (playerPT.mutiny > 0 ? playerPT.mutiny : 0) +
      playerPT.seed +
      playerPT.censure +
      playerPT.crown +
      playerPT.shard +
      playerPT.rider +
      playerPT.imperial +
      playerPT.ixth +
      playerPT.emphidia +
      playerPT.misc;

    // do we show the bonus point dots below or not?
    const showBonus = bonusTotal > 0 || playerPT.mutiny === -1;

    // what are the bonus properties we care about for determining if we show bonus block?
    const bonusProperties = [
      'imperial',
      'custodians',
      'mutiny',
      'seed',
      'censure',
      'crown',
      'shard',
      'rider',
      'ixth',
      'emphidia',
    ];

    // does imperial have a score above 0?
    const hasOnlyImperialAboveZero = _.every(bonusProperties, (property) => {
      if (property === 'imperial') {
        return playerPT[property] > 0;
      } else {
        return playerPT[property] === 0;
      }
    });

    // do all other relevant properties have a score === to 0?
    const otherPropertiesAreZero = _.some(bonusProperties, (property) => {
      return property !== 'imperial' && playerPT[property] === 0;
    });

    // if both are true, we can continue to allow bonus to be shown
    const onlyImperial = hasOnlyImperialAboveZero && otherPropertiesAreZero;
    return (
      <Block
        ref={ref}
        color={player.color}
        aria-labelledby="faction"
        localExpand={localExpand}
      >
        <Details>
          <FacButton
            focused={focused}
            onClick={() => setLocalExpand(!localExpand)}
          >
            <H1 id="faction">
              <Svg
                expanded={localExpand}
                src="/chevron_right_white_24dp.svg"
                alt=""
              />
              {player.faction.name}
              <Img src={player.faction.icon} alt="" />
            </H1>
            {player.name !== '' && <Span>{player.name}</Span>}
          </FacButton>
          <Flex>
            <H2 aria-label="Total Points">{player.totalPoints}</H2>
            <Color
              color={player.color}
              onClick={focusFaction}
              alt={`Focus on ${player.faction.name}`}
              focused={focused}
            />
          </Flex>
        </Details>
        {localExpand ? (
          <Grid>
            <FlexColumn>
              <ObjectiveBlock
                label="Stage 1"
                scored={player.scored_objectives.stage_1}
              />
              {(currentRound >= 5 ||
                (currentRound <= 4 && availStage2 > 0)) && (
                <ObjectiveBlock
                  label="Stage 2"
                  scored={player.scored_objectives.stage_2}
                />
              )}
              {player.point_by_type.classified > 0 && (
                <ObjectiveBlock
                  label="Classified"
                  scored={player.scored_objectives.classified}
                />
              )}
              {showBonus && !onlyImperial && (
                <BonusBlock label="Bonus" player={player} />
              )}
            </FlexColumn>
            <FlexColumn align="end">
              <ScoreBlock
                label="Secrets"
                type="secret"
                max={player.hasObsidian ? maxSecret + 1 : maxSecret}
                scored={player.point_by_type.secret}
                id={player.id}
              />
              <ScoreBlock
                label="Support"
                type="support"
                max={playerCount - 1}
                scored={player.point_by_type.support}
                id={player.id}
              />
              {misc && (
                <ScoreBlock
                  label="Homebrew"
                  type="misc"
                  max={player.point_by_type.misc}
                  scored={player.point_by_type.misc}
                  id={player.id}
                  disabled={player.totalPoints >= scoreLimit}
                />
              )}
              {custodiansClaimed ? (
                <ScoreBlock
                  label="Imperial"
                  type="imperial"
                  max={player.point_by_type.imperial}
                  scored={player.point_by_type.imperial}
                  id={player.id}
                  disabled={player.totalPoints >= scoreLimit}
                />
              ) : (
                <Button size="xs" outline onClick={toggleCustodianModal}>
                  Claim Custodians
                </Button>
              )}
            </FlexColumn>
          </Grid>
        ) : (
          <FlexBalls>
            <ScoreTags
              total={player.point_by_type.stage_1}
              color={colors.stage_1}
              type="stage_1"
              label="Stage 1 Points"
            />
            <ScoreTags
              total={player.point_by_type.stage_2}
              color={colors.stage_2}
              type="stage_2"
              label="Stage 2 Points"
            />
            <ScoreTags
              total={player.point_by_type.secret}
              color={colors.secret}
              type="secret"
              label="Secret Objective Points"
            />
            <ScoreTags
              total={player.point_by_type.support}
              color={player.color}
              label="Support for the Throne Points"
            />
            <ScoreTags
              total={bonusTotal + playerPT.classified}
              color={colors.actionColor}
              end="end"
              label={`Bonus Points (e.g. Imperial, Custodians, Relics, ${
                misc && 'Homebrew'
              })`}
            />
          </FlexBalls>
        )}
      </Block>
    );
  }
);

export default FactionScorer;
