import styled from 'styled-components';
import { breakpoints } from '../constants/variables';
import Checkbox from './Checkbox';
import { useGameStore } from '../stores/gameStore';

const Section = styled.section`
  @media only screen and (max-width: ${breakpoints.xs}) {
    width: 100%;
  }
`;

const H1 = styled.h1`
  text-align: center;
`;

const MiscPoints = () => {
  const [setMiscPoints, misc] = useGameStore((state) => [
    state.setMiscPoints,
    state.misc,
  ]);
  return (
    <Section aria-labelledby="variant">
      <H1 id="variant">Enable Homebrew Scoring</H1>
      <Checkbox
        id="misc"
        checked={misc}
        onChange={setMiscPoints}
        label="Homebrew Points"
        helpText="Adds a generic score toggle inside faction cards"
        name="misc_points"
      />
    </Section>
  );
};

export default MiscPoints;
